import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import Message from "../components/Message";
import Meta from "../components/Meta";
import { addToCart, removeFromCart } from "../slices/cartSlice";

const CartScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentPath = useLocation().pathname;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const continueShoppingHandler = () => {
    navigate(-1); // Navigate back in history
  };

  const addToCartHandler = async (product, qty) => {
    dispatch(addToCart({ ...product, qty }));
  };

  const removeFromCartHandler = async (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    navigate("/login?redirect=/shipping");
  };

  const guestCheckoutHandler = () => {
    navigate("/guest-checkout");
  };

  return (
    <>
      <Meta
        title="Your Shopping Cart | Lugo Parts & Restoration"
        description="Review your selected classic GMC truck parts in your cart. Proceed to checkout for a seamless and secure shopping experience."
        keywords="Cart, Review, Classic GMC Trucks, Parts, Checkout, Seamless, Secure, Shopping, Experience, Vintage, Automotive, Quality, Professional, Reliable, Customer Service, Online, Payment, Summary, Details"
      />

      <Row>
        <Col md={8}>
          <h1 style={{ marginBottom: "20px" }}>Shopping Cart</h1>
          {cartItems.length === 0 ? (
            <Message>
              Your cart is empty <Link to="/store-front">Go Back</Link>
            </Message>
          ) : (
            <>
              <ListGroup variant="flush">
                {cartItems.map((item) => (
                  <ListGroup.Item key={item._id}>
                    <Row>
                      <Col md={2}>
                        <Image src={item.image} alt={item.name} fluid rounded />
                      </Col>
                      <Col md={3}>
                        <Link
                          to={`/product/${item._id}?goBackPath=${currentPath}`}
                        >
                          {item.name}
                        </Link>
                      </Col>
                      <Col md={2}>${item.price}</Col>
                      <Col md={2}>
                        <Form.Control
                          as="select"
                          value={item.qty}
                          onChange={(e) =>
                            addToCartHandler(item, Number(e.target.value))
                          }
                        >
                          {[...Array(item.countInStock).keys()].map((x) => (
                            <option key={x + 1} value={x + 1}>
                              {x + 1}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          variant="light"
                          onClick={() => removeFromCartHandler(item._id)}
                        >
                          <FaTrash />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <Button
                className="btn btn-light my-3"
                onClick={continueShoppingHandler}
              >
                Continue Shopping
              </Button>
            </>
          )}
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush" style={{ border: "1px solid #636466" }}>
              <ListGroup.Item>
                <h2>
                  Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}
                  ) items
                </h2>
                $
                {cartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(2)}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cartItems.length === 0}
                  onClick={checkoutHandler}
                >
                  Proceed To Checkout
                </Button>
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  className="btn btn-light"
                  onClick={guestCheckoutHandler}
                >
                  Guest Checkout
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CartScreen;
