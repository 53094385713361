import React from "react";
import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  ListGroup,
  Button,
  Form,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Meta from "../components/Meta";
import {
  useSubmitRestorationMutation,
  useUploadRestorationSubmissionFileMutation,
} from "../slices/submissionApiSlice"; // Import the hook
import Loader from "../components/Loader";

const RestorationScreen = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [brandModel, setBrandModel] = useState("");
  const [description, setDescription] = useState("");
  // TODO: Implement file upload functionality
  const [file, setFile] = useState(null);

  const [submitRestoration, { isLoading: isSubmitting }] =
    useSubmitRestorationMutation(); // Use the hook
  const [uploadSubmissionFile, { isLoading: isUploading }] =
    useUploadRestorationSubmissionFileMutation(); // Use the hook for file uploads

  const uploadFileHandler = async (e) => {
    const fileData = new FormData();
    fileData.append("file", e.target.files[0]);
    fileData.append("formType", "restoration");
    try {
      const uploadResponse = await uploadSubmissionFile(fileData).unwrap();
      toast.success("File uploaded successfully!");
      setFile(uploadResponse.file); // Store the file path in state
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      phone,
      subject,
      brandModel,
      description,
      file,
    };
    try {
      await submitRestoration(formData).unwrap();
      toast.success("Submitting form...");
      navigate("/restoration");
      toast.success("Form and file submitted successfully!");
      // Clear the form
      setName("");
      setEmail("");
      setPhone("");
      setSubject("");
      setBrandModel("");
      setDescription("");
      setFile(null);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Meta
        title="Restoration Services | Lugo Parts & Restoration"
        description="Discover our specialized restoration services for classic GMC trucks. From minor touch-ups to full-scale restorations, we bring your vintage GMC trucks back to life with unmatched quality and craftsmanship."
        keywords="Restoration Services, Classic GMC Trucks, Specialized, Minor Touch-Ups, Full-Scale, Vintage, Quality, Craftsmanship, Expertise, Professional, Authentic, Customization, Rebuild, Revive, Originality, Preservation, Refurbishment"
      />

      <Container>
        {/* Restoration 1 */}
        <section className="about-me mt-5 mb-5">
          <h3 className="mb-5">Comprehensive Restoration Services</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Our commitment to excellence is evident in our full
                    restoration service, which entails a meticulous frame-off
                    restoration process. No detail goes overlooked as we
                    meticulously dismantle, inspect, and restore each component,
                    ensuring that every nut and bolt receives the care and
                    attention it deserves.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <Image
                src="/images/compressed/webp/Restoration01.webp"
                fluid
                rounded
              />
            </Col>
          </Row>
        </section>

        {/* Featured Restoration Section */}
        <section className="featured-restoration mt-5 mb-5">
          <h3 className="mb-5">Featured Restoration Projects</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <Image
                src="/images/compressed/webp/Restoration02.webp"
                fluid
                rounded
              />
            </Col>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    The result is a true automotive masterpiece that seamlessly
                    marries classic design with modern precision. Whether you're
                    seeking to rekindle the glory of a bygone era or are in
                    pursuit of the perfect finishing touch for your vintage
                    vehicle, Lugo Parts is your trusted partner in breathing new
                    life into automotive legends.
                    <br />
                    <br />
                    Join us on this journey to relive history through
                    meticulously restored classic vehicles.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Restoration Process */}
        <section className="restoration-process mt-5 mb-5">
          <h3 className="mb-5">Our Restoration Process</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Our restoration process is a labor of love that starts with
                    a detailed assessment and ends with a masterpiece. From
                    engine rebuilds to upholstery, we handle every aspect with
                    precision and care.
                    <br />
                    <br />
                    Our multi-step process ensures that each vehicle is restored
                    to its former glory, capturing the essence of its original
                    design.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <Image
                src="/images/compressed/webp/Restoration03.webp"
                fluid
                rounded
              />
            </Col>
          </Row>
        </section>

        {/* Your Journey Begins Here */}
        <section className="your-journey-begins mt-5 mb-5">
          <h3 className="mb-5">Your Journey Begins Here</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <Image
                src="/images/compressed/webp/Restoration05.webp"
                fluid
                rounded
              />
            </Col>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    The road to a fully restored classic vehicle is a journey
                    filled with excitement, nostalgia, and endless
                    possibilities. At Lugo Parts & Restoration, we're not just
                    restoring vehicles; we're restoring dreams.
                    <br />
                    <br />
                    As you prepare to take the next step, know that we're here
                    to guide you through every twist and turn. Your journey
                    begins here, and we can't wait to see where it takes us.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Custom Restoration Inquiry Section */}
        <section className="custom-inquiry mt-5 mb-5">
          <h3 className="mb-5" style={{ textAlign: "center" }}>
            Inquire About Your Restoration Project
          </h3>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Dreaming of hitting the open road in a fully restored
                    1955-1959 Chevrolet or GMC truck? You're at the right place!
                    At Lugo Parts, we specialize in bringing these vintage
                    beauties back to life, and we'd love to hear about your
                    dream project.
                    <br />
                    <br />
                  </p>
                  <p className="landing-page-text">
                    Fill out the form below with your specific needs and let's
                    make your dream truck a reality. Our team of experts will
                    review your inquiry and get back to you with tailored
                    options to meet your unique requirements.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="my-3">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="email" className="my-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="phone" className="my-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="subject" className="my-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="brandModel" className="my-3">
              <Form.Label>Brand/Model</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Brand or Model (or both)"
                value={brandModel}
                onChange={(e) => setBrandModel(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="description" className="my-3">
              <Form.Label>Restoration Goals</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Describe your restoration needs..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="file" className="my-3">
              <Form.Label>File Upload (PDF)</Form.Label>
              <Form.Control type="file" onChange={uploadFileHandler} />
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
              className="mt-2"
              disabled={isSubmitting || isUploading}
            >
              Submit Inquiry
            </Button>
            {(isSubmitting || isUploading) && <Loader />}
          </Form>
        </section>

        {/* ... other sections ... */}
      </Container>
    </>
  );
};

export default RestorationScreen;
