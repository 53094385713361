import React from "react";
import { useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Row, Col, ListGroup, Image, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import {
  useGetOrderDetailsQuery,
  usePayOrderMutation,
  useGetPayPalClientIdQuery,
  useDeliverOrderMutation,
} from "../slices/ordersApiSlice";

const OrderScreen = () => {
  const { id: orderId } = useParams();

  const currentPath = useLocation().pathname;

  const {
    data: order,
    refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId);

  const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation();

  const [deliverOrder, { isLoading: loadingDeliver }] =
    useDeliverOrderMutation();

  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer();

  const {
    data: paypal,
    isLoading: loadingPayPal,
    error: errorPayPal,
  } = useGetPayPalClientIdQuery();

  const { userInfo } = useSelector((state) => state.auth);

  const CouponInfoBox = () => (
    <Card
      className="my-3"
      style={{ border: "1px solid #636466", borderRadius: "0.5rem" }}
    >
      <Card.Body style={{ padding: "1rem 1rem 1rem 0.25rem" }}>
        {" "}
        {/* Adjust left padding */}
        <Card.Title as="h5" style={{ fontSize: "1rem" }}>
          Coupon Applied
        </Card.Title>
        <Card.Text style={{ fontSize: "0.9rem", marginTop: "0.5rem" }}>
          A discount of <strong>{order.discountPercentage}%</strong> was applied
          with code <strong>"{order.couponCode}"</strong>
        </Card.Text>
      </Card.Body>
    </Card>
  );

  useEffect(() => {
    if (!errorPayPal && !loadingPayPal && paypal.clientId) {
      const loadPayPalScript = async () => {
        paypalDispatch({
          type: "resetOptions",
          value: {
            "client-id": paypal.clientId,
            currency: "USD",
          },
        });
        paypalDispatch({ type: "setLoadingStatus", value: "pending" });
      };
      if (order && !order.isPaid) {
        if (!window.paypal) {
          loadPayPalScript();
        }
      }
    }
  }, [order, paypal, paypalDispatch, loadingPayPal, errorPayPal]);

  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        await payOrder({ orderId, details }).unwrap();
        refetch();
        toast.success("Payment successful!");
      } catch (err) {
        toast.error(err?.data?.message || err.message);
      }
    });
  }

  function onError(err) {
    toast.error(err.message);
  }

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: order.totalPrice,
            },
          },
        ],
      })
      .then((orderId) => {
        return orderId;
      });
  }

  const deliverOrderHandler = async () => {
    try {
      await deliverOrder(orderId);
      refetch();
      toast.success("Order Delivered");
    } catch (err) {
      toast.error(err?.data?.message || err.message);
    }
  };

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <>
      <Meta title={`Order ${order._id}`} />
      <h1>Order {order._id}</h1>
      {!order.isPaid && <h2>Payment Required</h2>}
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              {order.guestUser ? (
                <>
                  <p>
                    <strong>Email: </strong> {order.guestEmail}
                    <br />
                    <strong>Guest User:</strong> Yes
                  </p>
                  <p>
                    <strong>Name: </strong> {order.shippingAddress.name}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Name: </strong> {order.shippingAddress.name}
                  </p>

                  <p>
                    <strong>Email: </strong> {order.user.email}
                  </p>
                </>
              )}
              <p>
                <strong>Phone: </strong> {order.shippingAddress.phone}
              </p>
              <p>
                <strong>Address:</strong>
                <br />
                {order.shippingAddress.address}
                {order.shippingAddress.apartment && (
                  <>
                    {" "}
                    <br /> {order.shippingAddress.apartment}
                  </>
                )}
                <br />
                {order.shippingAddress.city}
                <br />
                {order.shippingAddress.state},{" "}
                {order.shippingAddress.postalCode}
                <br />
                {order.shippingAddress.country}
              </p>
              <br />
              {order.isDelivered ? (
                <Message variant="success">
                  Delivered on{" "}
                  {new Date(order.deliveredAt)
                    .toLocaleString("en-US", {
                      timeZone: "America/Los_Angeles", // Pacific Time (PT)
                      hour12: false,
                    })
                    .substring(0, 19) + " (PT)"}
                </Message>
              ) : (
                <Message variant="danger">Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>
              <br />
              {order.isPaid ? (
                <Message variant="success">
                  Paid on{" "}
                  {new Date(order.paidAt)
                    .toLocaleString("en-US", {
                      timeZone: "America/Los_Angeles", // Pacific Time (PT)
                      hour12: false,
                    })
                    .substring(0, 19) + " (PT)"}
                </Message>
              ) : (
                <Message variant="danger">Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.map((item, index) => (
                <ListGroup.Item key={index}>
                  <Row>
                    <Col md={1}>
                      <Image src={item.image} alt={item.name} fluid rounded />
                    </Col>
                    <Col>
                      <Link
                        to={`/product/${item.product}?goBackPath=${currentPath}`}
                      >
                        {item.name}
                      </Link>
                    </Col>
                    <Col md={4}>
                      {item.qty} x ${item.price} = ${item.qty * item.price}
                    </Col>
                  </Row>
                  {/* Conditionally render extra details for admins */}
                  {userInfo && userInfo.isAdmin && (
                    <Row style={{ marginTop: "0.625rem" }}>
                      <Col md={12}>
                        <strong>Lugo Part #: </strong>
                        {item.lugoPart}

                        <br />
                        <strong>{item.vendor} Part #: </strong>
                        {item.category}
                      </Col>
                    </Row>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush" style={{ border: "1px solid #636466" }}>
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Item(s) Subtotal:</Col>
                  <Col>${order.itemsPrice}</Col>
                </Row>

                <Row>
                  <Col>
                    Estimated Shipping<sup>*</sup>:
                  </Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>

                <Row>
                  <Col>
                    Estimated Handling Fee<sup>*</sup>:
                  </Col>
                  <Col>${order.handlingFee}</Col>
                </Row>

                <Row>
                  <Col>Estimated Tax:</Col>
                  <Col>${order.taxPrice}</Col>
                </Row>

                <Row>
                  <Col>Total:</Col>
                  <Col>${order.totalPrice}</Col>
                </Row>
                <Row>{order.couponCode && <CouponInfoBox />}</Row>
                <Row>
                  <Col xs={12}>
                    <small className="text-muted">
                      <sup>*</sup>Shipping cost is estimated; overages or
                      shortages will be reconciled.
                    </small>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs={12}>
                    <small className="text-muted">
                      <sup>*</sup>Our handling fee covers the costs of order
                      processing and fulfillment, ensuring accurate and timely
                      delivery of your purchase. This modest charge supports
                      operational expenses and fair compensation for our
                      dedicated team.
                    </small>
                  </Col>
                </Row>
              </ListGroup.Item>

              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay && <Loader />}

                  {isPending ? (
                    <Loader />
                  ) : (
                    <div>
                      <div>
                        <PayPalButtons
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onError={onError}
                        ></PayPalButtons>
                      </div>
                    </div>
                  )}
                </ListGroup.Item>
              )}

              {loadingDeliver && <Loader />}

              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type="button"
                      className="btn btn-block"
                      onClick={deliverOrderHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderScreen;
