import React from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  ListGroup,
  Form,
  Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Meta from "../components/Meta";
import {
  useSubmitCareerMutation,
  useUploadCareerSubmissionFileMutation,
} from "../slices/submissionApiSlice";
import Loader from "../components/Loader";

const CareerScreen = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [jobCategory, setJobCategory] = useState(""); // Job Category
  const [coverLetter, setCoverLetter] = useState(null); // Cover Letter
  const [resume, setResume] = useState(null); // Resume File

  const [submitCareer, { isLoading: isSubmitting }] = useSubmitCareerMutation();
  const [uploadCareerSubmissionFile, { isLoading: isUploading }] =
    useUploadCareerSubmissionFileMutation();

  const uploadFileHandler = async (e, fileType) => {
    const fileData = new FormData();
    fileData.append("file", e.target.files[0]);
    fileData.append("formType", "career");
    fileData.append("fileType", fileType); // either 'coverLetter' or 'resume'
    try {
      const uploadResponse = await uploadCareerSubmissionFile(
        fileData
      ).unwrap();
      toast.success(`${fileType} uploaded successfully!`);
      if (fileType === "coverLetter") {
        setCoverLetter(uploadResponse.file);
      } else {
        setResume(uploadResponse.file);
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      phone,
      jobCategory,
      coverLetter,
      resume,
    };
    try {
      await submitCareer(formData).unwrap();
      toast.success("Submitting form...");
      navigate("/career");
      toast.success("Application submitted successfully!");

      // Clear the form (Optional)
      setName("");
      setEmail("");
      setPhone("");
      setJobCategory("");
      setCoverLetter(null);
      setResume(null);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Meta
        title="Careers | Lugo Parts & Restoration"
        description="Join our team of passionate experts specializing in classic GMC truck restorations. Explore career opportunities that allow you to turn your passion for vintage GMC trucks into a rewarding profession."
        keywords="Careers, Team, Passionate, Classic GMC Trucks, Restoration, Experts, Opportunities, Vintage, Rewarding, Profession, Job Openings, Apply, Skills, Craftsmanship, Quality, Professional, Automotive, Culture, Benefits"
      />

      <Container>
        {/* Join Our Passion-Driven Team */}
        <section className="about-us mt-5 mb-5">
          <h3 className="mb-5">Join Our Passion-Driven Team</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Are you ready to embark on a transformative journey in the
                    world of classic vehicle restoration? Become an integral
                    part of our passion-driven team at Lugo Parts and
                    Restoration.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <Image
                src="/images/compressed/webp/Careers01.webp"
                fluid
                rounded
              />
            </Col>
          </Row>
        </section>

        {/* Why Lugo Parts & Restoration */}
        <section className="why-lugo mt-5 mb-5">
          <h3 className="mb-5">Why Lugo Parts & Restoration?</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <Image
                src="/images/compressed/webp/Restoration06.webp"
                fluid
                rounded
              />
            </Col>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    At Lugo Parts & Restoration, we believe that the key to a
                    successful restoration project lies in the hands of the
                    people who bring it to life. Our team is a diverse group of
                    skilled artisans, engineers, and enthusiasts who share a
                    common passion for classic vehicles.
                    <br />
                    <br />
                    We offer a collaborative work environment that encourages
                    innovation and rewards dedication. If you're looking to turn
                    your passion into a fulfilling career, you've found your
                    home.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Career Opportunities */}
        <section className="career-opportunities mt-5 mb-5">
          <h3 className="mb-5">Explore Our Career Opportunities</h3>
          <p className="landing-page-text">
            Use the form below to apply for a position at Lugo Parts &
            Restoration. Complete all required fields, attach your resume, and
            feel free to include a cover letter to introduce yourself. We look
            forward to potentially having you join our talented team!
          </p>
        </section>

        {/* Submit Your Application */}
        <section className="submit-application mt-5 mb-5">
          <h3 className="mb-5" style={{ textAlign: "center" }}>
            Submit Your Application
          </h3>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="my-3">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="email" className="my-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="phone" className="my-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="jobCategory" className="my-3">
              <Form.Label>Job Category</Form.Label>
              <Form.Select
                value={jobCategory}
                onChange={(e) => setJobCategory(e.target.value)}
                required
              >
                <option value="">Select Job Category</option>
                <option value="Automotive Painter">Automotive Painter</option>
                <option value="Body Technician">Body Technician</option>
                <option value="Metal Fabricator">Metal Fabricator</option>
                <option value="Shop Helper">Shop Helper</option>
                <option value="Receptionist">Receptionist</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="coverLetter" className="my-3">
              <Form.Label>Cover Letter (PDF only)</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf"
                onChange={(e) => uploadFileHandler(e, "coverLetter")}
                required
              />
            </Form.Group>

            <Form.Group controlId="resume" className="my-3">
              <Form.Label>Upload Resume (PDF only)</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf"
                onChange={(e) => uploadFileHandler(e, "resume")}
                required
              />
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
              className="mt-2"
              disabled={isSubmitting || isUploading}
            >
              Submit Application
            </Button>
            {(isSubmitting || isUploading) && <Loader />}
          </Form>
        </section>
      </Container>
    </>
  );
};

export default CareerScreen;
