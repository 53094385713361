import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import Meta from "../components/Meta";
import { useGetFilteredProductsQuery } from "../slices/productsApiSlice";

const NapcoStoreScreen = () => {
  const { pageNumber } = useParams();
  const { data, isLoading, error } = useGetFilteredProductsQuery({
    brand: "NAPCO",
    pageNumber,
  });
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate("/store-front");
  };

  return (
    <>
      <Row className="justify-content-left mt-4">
        <Col md={6} className="text-left">
          <Button onClick={goBackHandler} variant="light">
            Back to Store Front
          </Button>
        </Col>
      </Row>
      <Container className="mt-5 mb-5">
        <h1 className="mb-3 text-center">NAPCO Parts</h1>
        <Meta title="NAPCO Parts | Lugo Parts & Restoration" />

        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : data && data.products.length > 0 ? (
          <>
            <Row>
              {data.products.map((product) => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
            </Row>
            <Paginate
              pages={data.pages}
              page={data.page}
              keyword={""} // Update as needed
            />
          </>
        ) : (
          <Message>No NAPCO products found</Message>
        )}
      </Container>
    </>
  );
};

export default NapcoStoreScreen;
