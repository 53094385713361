import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaTimes, FaCheck } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import { useGetRestorationQuery } from "../../slices/submissionApiSlice";
import GenericPaginateAdmin from "../../components/GenericPaginateAdmin";

const RestorationListScreen = () => {
  const { pageNumber } = useParams();
  const { data, isLoading, error } = useGetRestorationQuery({
    // keyword,
    pageNumber,
  });

  return (
    <>
      <Meta
        title="Restoration List | Admin | Lugo Parts & Restoration"
        description="Admin panel for managing restoration projects at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>

      <h1>Restoration Submissions</h1>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>PHONE</th>
                <th>BRAND</th>
                <th>FILE SUBMISSION</th>
                <th>Submitted On (PT)</th>
                <th>DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {data.restorations.map((resto) => (
                <tr key={resto._id}>
                  <td>{resto._id}</td>
                  <td>{resto.name}</td>
                  <td>{resto.email}</td>
                  <td>{resto.phone}</td>
                  <td>{resto.brandModel}</td>
                  <td>
                    {resto.file ? (
                      <FaCheck style={{ color: "green" }} />
                    ) : (
                      <FaTimes style={{ color: "red" }} />
                    )}
                  </td>
                  <td>
                    {new Date(resto.createdAt)
                      .toLocaleString("en-US", {
                        timeZone: "America/Los_Angeles", // Pacific Time (PT)
                        hour12: false,
                      })
                      .substring(0, 19) + " (PT)"}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/restoration/${resto._id}`}>
                      <Button variant="light" className="btn-sm">
                        Details
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <GenericPaginateAdmin
            pages={data.pages}
            page={data.page}
            type="restoration"
          />
        </>
      )}
    </>
  );
};

export default RestorationListScreen;
