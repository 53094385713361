import StateTaxRatesFront from "./StateTaxRatesFront.js";

export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state, couponDiscount = null) => {
  // Calculate Items Price
  let itemsPrice = state.cartItems.reduce(
    (acc, item) => acc + item.price * item.qty,
    0
  );

  // Apply coupon discount if available
  if (couponDiscount) {
    const discountAmount = itemsPrice * (couponDiscount / 100);
    itemsPrice -= discountAmount;
  }

  // Format itemsPrice with two decimals
  state.itemsPrice = addDecimals(Number(itemsPrice));

  // Initialize shipping price
  // Shipping price for napco set at $30
  let shippingPrice = 0;
  const napcoShippingPrice = 30; // Flat rate for NAPCO products

  // Split cart items into NAPCO and non-NAPCO groups
  const napcoItems = state.cartItems.filter((item) => item.brand === "NAPCO");
  const nonNapcoItems = state.cartItems.filter(
    (item) => item.brand !== "NAPCO"
  );

  // Calculate the total price of non-NAPCO items after discount
  const nonNapcoDiscountedPrice = nonNapcoItems.reduce(
    (acc, item) =>
      acc +
      item.price * item.qty * (couponDiscount ? 1 - couponDiscount / 100 : 1),
    0
  );

  // Calculate shipping for NAPCO and non-NAPCO items
  if (napcoItems.length > 0) {
    const totalNapcoQty = napcoItems.reduce((acc, item) => acc + item.qty, 0);
    shippingPrice += napcoShippingPrice * totalNapcoQty;
  }
  if (nonNapcoItems.length > 0) {
    shippingPrice += 0.1 * nonNapcoDiscountedPrice; // 10% for non-NAPCO items
  }

  // Format shippingPrice with two decimals
  state.shippingPrice = addDecimals(Number(shippingPrice));

  // Calculate Handling Fee (4% of totalPrice + shippingPrice)
  const handlingFee = 0.04 * (itemsPrice + shippingPrice);
  state.handlingFee = addDecimals(Number(handlingFee));

  // Calculate Tax Price
  const stateTax = state.shippingAddress.state;
  const taxRate = StateTaxRatesFront[stateTax] || 0;
  const taxPrice = taxRate * itemsPrice;
  state.taxPrice = addDecimals(Number(taxPrice));

  // Calculate Total Price
  const totalPrice = itemsPrice + shippingPrice + handlingFee + taxPrice;
  state.totalPrice = addDecimals(Number(totalPrice));

  localStorage.setItem("cart", JSON.stringify(state));

  return state;
};
