import React from "react";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import sitemaintenance from "../assets/sitemaintenance.png";

//ERROR 404 SCREEN

const NotFoundScreen = () => {
  return (
    <>
      <Meta
        title="Page Not Found | Lugo Parts & Restoration"
        description="The page you are looking for could not be found. Please navigate back to the homepage."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>

      <div style={{ textAlign: "center", padding: "2rem" }}>
        <h1>Oops! You're Off the Map!</h1>
        <p>
          We couldn't find the page you're looking for. But don't worry, we'll
          get you back on track!
        </p>
        <img
          src={sitemaintenance}
          alt="Site Maintenance"
          style={{ width: "300px", marginTop: "2rem" }}
        />
        <div style={{ marginTop: "2rem" }}>
          <Link to="/" className="btn btn-primary">
            Go Home
          </Link>
        </div>
        <div style={{ marginTop: "2rem" }}>
          <p>
            For inquiries or support, please{" "}
            <Link to="/contactus">contact us</Link>.
          </p>
        </div>
      </div>
    </>
  );
};

export default NotFoundScreen;
