// export const BASE_URL =
//   process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";
export const BASE_URL = "";
export const PRODUCTS_URL = "/api/products";
export const USERS_URL = "/api/users";
export const ORDERS_URL = "/api/orders";
export const PAYPAL_URL = "/api/config/paypal";
export const UPLOAD_URL = "/api/upload";
export const SUBMISSION_UPLOAD_URL = "/api/submissionUpload";
export const RESTORATION_URL = "/api/restoration";
export const PART_URL = "/api/part";
export const CAREERS_URL = "/api/careers";
export const COUPONS_URL = "/api/coupons";
export const GUESTS_URL = "/api/guests";
