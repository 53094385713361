import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import ProductCarousel from "../components/ProductCarousel";
import AdvancedFilter from "../components/AdvancedFilter";
import Meta from "../components/Meta";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import { useGetFilteredProductsQuery } from "../slices/productsApiSlice";

const HomeScreen = () => {
  const { pageNumber, keyword } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [filters, setFilters] = React.useState(() => {
    const savedFilters = localStorage.getItem("filters");
    return savedFilters
      ? JSON.parse(savedFilters)
      : {
          brand: [],
          partType: [],
          yearRange: [],
        };
  });

  const filterTitleMapping = {
    brand: "Brand",
    partType: "Category",
    yearRange: "Year Range",
  };

  const formatFilters = () => {
    const filterEntries = Object.entries(filters).filter(
      ([, values]) => values.length > 0
    );
    return filterEntries
      .map(
        ([filterType, values]) =>
          `${filterTitleMapping[filterType] || filterType}: ${values.join(
            ", "
          )}`
      )
      .join(" | ");
  };

  // Update local storage whenever the filter state changes
  React.useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  const { brand, partType, yearRange } = filters;

  const { data, isLoading, error } = useGetProductsQuery({
    keyword,
    pageNumber,
  });

  const {
    data: filteredData,
    isLoading: isNewLoading,
    error: newError,
  } = useGetFilteredProductsQuery({
    keyword,
    pageNumber,
    brand: filters.brand.join(","),
    partType: filters.partType.join(","),
    yearRange: filters.yearRange.join(","), // Send as a comma-separated string
  });

  const shouldUseFilteredData =
    brand.length > 0 || partType.length > 0 || yearRange.length > 0;

  const finalData = shouldUseFilteredData ? filteredData : data;
  const finalLoading = shouldUseFilteredData ? isNewLoading : isLoading;
  const finalError = shouldUseFilteredData ? newError : error;

  const resetFilters = () => {
    setFilters({
      brand: [],
      partType: [],
      yearRange: [],
    });
  };

  const goToStoreFront = () => {
    resetFilters();
    navigate("/store-front");
  };

  return (
    <>
      <Container className="mt-3 mb-3">
        <Row>
          <Col sm={12} md={{ span: 4, offset: 0 }}>
            {" "}
            {/* Adjust column width and offset as needed */}
            <Button variant="light" onClick={goToStoreFront}>
              Back to Store Front
            </Button>
          </Col>
        </Row>
      </Container>
      <Container>
        {" "}
        {/* Middle formatting container */}
        {!keyword && !shouldUseFilteredData ? (
          <ProductCarousel />
        ) : (
          <Link
            to="/store"
            className="btn btn-light mb-4"
            onClick={resetFilters}
          >
            Clear Filters
          </Link>
        )}
        <h1 className="mb-3">
          {keyword
            ? `Showing results for "${keyword}"`
            : shouldUseFilteredData
            ? "Filtered Products"
            : "New Aftermarket Products"}
        </h1>
        {shouldUseFilteredData && (
          <p className="mb-3">Selected Filters: {formatFilters()}</p>
        )}
      </Container>

      <Container fluid>
        {" "}
        {/* Full width container */}
        <Row>
          <Col md={2} className="no-right-padding">
            <AdvancedFilter
              className="no-right-padding"
              filters={filters}
              setFilters={setFilters}
            />
          </Col>
          <Col md={8}>
            <Container>
              {" "}
              {/* Middle formatting container for products */}
              {finalLoading ? (
                <Loader />
              ) : finalError ? (
                <Message variant="danger">
                  {finalError?.data?.message || finalError.error}
                </Message>
              ) : (
                <>
                  <Meta
                    title="Store | Lugo Parts & Restoration"
                    description="Shop for authentic and high-quality classic GMC truck parts in our online store. From engines to interiors, find everything you need for your GMC restoration project."
                    keywords="Store, Shop, Classic GMC Trucks, Authentic, High-Quality, Parts, Engines, Interiors, Online, Ecommerce, Restoration, Project, Vintage, Automotive, Shopping, Secure, Reliable, Wide Range, Exclusive, Customer Service, Quality Assurance"
                  />
                  <Row>
                    {finalData.products.map((product) => (
                      <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                        <Product product={product} />
                      </Col>
                    ))}
                  </Row>
                  <Paginate
                    pages={finalData.pages}
                    page={finalData.page}
                    keyword={keyword ? keyword : ""}
                  />
                </>
              )}
            </Container>
          </Col>
          <Col md={2} className="empty-right-section">
            {" "}
            {/* Empty right section */}
            {/* Optionally, you could add some content or styling here */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeScreen;
