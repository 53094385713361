import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords }) => {
  const [currentURL, setCurrentURL] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentURL(window.location.href);
    }
  }, []);

  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <link rel="canonical" href={currentURL} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentURL} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={currentURL} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {/* Schema Markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Organization",
          name: "Lugo Parts & Restoration",
          description: description,
          url: currentURL,
          logo: "https://4af47eb90fda59dc4e73.cdn6.editmysite.com/uploads/b/4af47eb90fda59dc4e73939cd699cd2f0d6851c86ca60829d770a959f11ea33d/logo%20jpg_1683148879.jpeg?width=400&optimize=medium",
          telephone: "+1-916-652-0840",
          address: {
            "@type": "PostalAddress",
            streetAddress: "3240 Taylor Road",
            addressLocality: "Loomis",
            addressRegion: "CA",
            postalCode: "95650",
            addressCountry: "USA",
          },
          sameAs: [
            "https://www.facebook.com/LugoParts",
            "https://twitter.com/lugoparts",
            "https://www.instagram.com/lugoparts_restoration/",
          ],
        })}
      </script>
      {/* GA4 (Google Analytics 4) */}
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Lugo Parts & Restoration | Classic Truck & Car Restorations",
  description:
    "Lugo Parts & Restoration is a premier Classic Truck & Car Restorations company dedicated to making dreams come true, one handshake at a time. With unparalleled expertise and craftsmanship, we breathe new life into vintage vehicles, preserving their timeless beauty and historical significance. Whether it's a cherished family heirloom or a treasured classic, our passion for automotive restoration ensures that every project is a work of art, bringing joy to enthusiasts and collectors alike.",
  keywords:
    "Classic, GMC, Truck, Restorations, Vintage, Vehicles, Automotive, Expertise, Craftsmanship, Timeless, Beauty, Historical, Significance, Family Heirloom, Collectors, Passion, Dreams, Handshake, Premier, Lugo Parts & Restoration, Customization, Rebuild, Revive, Originality, Preservation, Refurbishment, Automotive Artistry, Heritage, Legacy, Vintage Trucks, Antique Autos, Auto Enthusiasts, Nostalgia, Precision, Dedication, Masterpiece, Artisanal, Expert, Unique, Quality, Professional, Car, Vintage Cars",
};

export default Meta;
