import React from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormContainer from "../components/FormContainer";
import Meta from "../components/Meta";
import { saveShippingAddress } from "../slices/cartSlice";
import CheckoutSteps from "../components/CheckoutSteps";
import USStates from "../utils/USStates";

const ShippingScreen = () => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [name, setName] = useState(shippingAddress?.name || ""); //full name
  const [address, setAddress] = useState(shippingAddress?.address || ""); //address 1
  const [apartment, setApartment] = useState(shippingAddress?.apartment || ""); //address 2
  const [city, setCity] = useState(shippingAddress?.city || ""); //city
  const [state, setState] = useState(shippingAddress?.state || "");
  const [postalCode, setPostalCode] = useState(
    shippingAddress?.postalCode || ""
  ); //postal code
  const [country, setCountry] = useState(shippingAddress?.country || ""); //country
  const [phone, setPhone] = useState(shippingAddress?.phone || ""); // phone number

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      saveShippingAddress({
        name,
        address,
        apartment,
        city,
        state,
        postalCode,
        country,
        phone,
      })
    );
    navigate("/payment");
  };

  return (
    <>
      <Meta title="Shipping | Lugo Parts & Restoration" />
      <FormContainer>
        <CheckoutSteps step1 step2 />
        <h1>Shipping</h1>

        <Form onSubmit={submitHandler}>
          <Form.Group controlId="name" className="my-2">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="address" className="my-2">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="apartment" className="my-2">
            <Form.Label>Apartment/Suite/Unit</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Apartment, Suite, or Unit (optional)"
              value={apartment}
              onChange={(e) => setApartment(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="city" className="my-2">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            ></Form.Control>
          </Form.Group>

          {/* State Dropdown */}
          <Form.Group controlId="state" className="my-2">
            <Form.Label>State</Form.Label>
            <Form.Select
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
            >
              <option value="" disabled>
                Select State
              </option>
              {USStates.map((stateOption, index) => (
                <option key={index} value={stateOption}>
                  {stateOption}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="postalCode" className="my-2">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your ZIP or postal code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="country" className="my-2">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="phone" className="my-2">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Enter your phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" className="my-2">
            Continue
          </Button>
        </Form>
        <p className="text-muted mt-3">
          Please note: We currently offer shipping services to all U.S. states,
          with the exception of <strong>Hawaii</strong> and{" "}
          <strong>Alaska</strong>.
        </p>
      </FormContainer>
    </>
  );
};

export default ShippingScreen;
