import React from "react";
import { useState, useEffect, useCallback } from "react";
// import { FaArrowCircleUp } from "react-icons/fa";
import { IoArrowUpOutline } from "react-icons/io5";
import "../assets/styles/backToTop.css";

// Define the BackToTopButton component
const BackToTopButton = () => {
  const [visible, setVisible] = useState(false);

  // Define the toggleVisibility function using useCallback to memoize the function
  const toggleVisibility = useCallback(() => {
    if (window.pageYOffset > 100) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, []);

  // Define the scrollToTop function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Use useEffect to add and clean up the scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [toggleVisibility]);

  return (
    <div
      className={`back-top-btn ${visible ? "active" : ""}`}
      onClick={scrollToTop}
      aria-hidden={!visible} // Improve accessibility
    >
      {/* <FaArrowCircleUp size={30} /> */}
      <IoArrowUpOutline />
    </div>
  );
};

export default BackToTopButton;
