import React from "react";
import { useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import { useGetPartByIdQuery } from "../../slices/submissionApiSlice";

const PartDetailsScreen = () => {
  const { id } = useParams();
  const { data: part, isLoading, error } = useGetPartByIdQuery(id);

  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
  };

  return (
    <>
      <Meta
        title="Part Submission Details | Admin | Lugo Parts & Restoration"
        description="Admin panel for viewing details of parts at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Card>
          <Card.Header>
            <h2>Part Submission Details</h2>
          </Card.Header>
          <Card.Body>
            <h3>ID: {part._id}</h3>
            <h3>Name: {part.name}</h3>
            <h3>Email: {part.email}</h3>
            <h3>Phone: {part.phone}</h3>
            <h3>Subject: {part.subject}</h3>
            <h3>Part Type: {part.partType}</h3>
            <h3>Details: {part.details}</h3>
            <h3>
              Submitted On:{" "}
              {new Date(part.createdAt)
                .toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles", // Pacific Time (PT)
                  hour12: false,
                })
                .substring(0, 19) + " (PT)"}
            </h3>
            {part.file && (
              <Button
                variant="success"
                onClick={() => downloadFile(part.file, "parts-file")}
              >
                <FaDownload /> Download File
              </Button>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default PartDetailsScreen;
