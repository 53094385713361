import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { toast } from "react-toastify";
import { useResetPasswordMutation } from "../slices/usersApiSlice";

const ResetPasswordScreen = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const [resetPassword, { isLoading, isSuccess, isError, error }] =
    useResetPasswordMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      await resetPassword({ password, token }).unwrap();
      toast.success("Password successfully reset");
      navigate("/login");
    } catch (err) {
      toast.error(err?.data?.message || "Password reset failed");
    }
  };

  return (
    <FormContainer>
      <h1>Reset Password</h1>
      {isLoading && <Loader />}
      {isError && (
        <Message variant="danger">
          {error?.data?.message || "An error occurred"}
        </Message>
      )}
      {isSuccess && (
        <Message variant="success">
          Password has been reset successfully
        </Message>
      )}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId="password">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Group>

        <Button
          type="submit"
          variant="primary"
          className="mt-3"
          disabled={isLoading}
        >
          Reset Password
        </Button>
      </Form>
    </FormContainer>
  );
};

export default ResetPasswordScreen;
