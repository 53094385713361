import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaTimes, FaCheck } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import { useGetPartQuery } from "../../slices/submissionApiSlice";
import GenericPaginateAdmin from "../../components/GenericPaginateAdmin";

const PartListScreen = () => {
  const { pageNumber } = useParams();
  const { data, isLoading, error } = useGetPartQuery({ pageNumber });
  return (
    <>
      <Meta
        title="Parts Submission List | Admin | Lugo Parts & Restoration"
        description="Admin panel for managing parts at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>

      <h1>Part Submissions</h1>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>PHONE</th>
                <th>PART TYPE</th>
                <th>FILE SUBMISSION</th>
                <th>Submitted On (PT)</th>
                <th>DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {data.parts.map((part) => (
                <tr key={part._id}>
                  <td>{part._id}</td>
                  <td>{part.name}</td>
                  <td>{part.email}</td>
                  <td>{part.phone}</td>
                  <td>{part.partType}</td>
                  <td>
                    {part.file ? (
                      <FaCheck style={{ color: "green" }} />
                    ) : (
                      <FaTimes style={{ color: "red" }} />
                    )}
                  </td>
                  <td>
                    {new Date(part.createdAt)
                      .toLocaleString("en-US", {
                        timeZone: "America/Los_Angeles", // Pacific Time (PT)
                        hour12: false,
                      })
                      .substring(0, 19) + " (PT)"}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/part/${part._id}`}>
                      <Button variant="light" className="btn-sm">
                        Details
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <GenericPaginateAdmin
            pages={data.pages}
            page={data.page}
            type="part"
          />
        </>
      )}
    </>
  );
};

export default PartListScreen;
