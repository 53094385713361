import React from "react";
import { Row, Col, Image, Container, ListGroup } from "react-bootstrap";
import {
  FaRegCheckCircle,
  FaTshirt,
  FaHatCowboy,
  FaCar,
  FaGift,
  FaFacebook,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Meta from "../components/Meta";
import LandingProducts from "../components/LandingProducts";

const LandingScreen = () => {
  return (
    <>
      <Meta />
      <div className="image-container">
        {/* old one was LugoHeroNewBright.jpg */}
        <Image
          src="/images/compressed/webp/LugoHeroFinalNew.webp"
          alt="Lugo Parts & Restoration Shop"
          fluid
          className="full-width-image"
        />

        <div className="image-overlay d-none d-lg-block">
          <Link to="/store-front" className="btn btn-primary image-button">
            Visit Our Store
          </Link>
        </div>
      </div>

      <Container>
        {/* About Me Section */}
        <section className="about-me mt-5 mb-5">
          <h3 className="mb-5">
            Your Premier Classic Vehicle Restoration Destination
          </h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    At Lugo Parts, we are masters of reviving vintage vehicles,
                    with a special focus on 1955 to 1959 Chevrolet and GMC
                    trucks. Our passion for preserving automotive history drives
                    our dedication to every restoration project.
                    <br />
                    <br />
                  </p>
                  <p className="landing-page-text">
                    We offer both comprehensive and partial restoration services
                    to meet your unique requirements. Beyond restoration, we're
                    proud to offer a diverse selection of new and used parts
                    specifically for 1955-1959 Chevrolet and GMC trucks. Our
                    commitment ensures enthusiasts have access to the highest
                    quality components for their restoration endeavors.
                  </p>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaRegCheckCircle
                          style={{ marginRight: "0.25rem" }}
                          color="#d80607"
                        />{" "}
                        <span> Quality Work</span>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaRegCheckCircle
                          style={{ marginRight: "0.25rem" }}
                          color="#d80607"
                        />{" "}
                        <span> Expert Team</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaRegCheckCircle
                          style={{ marginRight: "0.25rem" }}
                          color="#d80607"
                        />
                        <span>Genuine Parts</span>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaRegCheckCircle
                          style={{ marginRight: "0.25rem" }}
                          color="#d80607"
                        />
                        <span>Competitive Pricing</span>
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              {/* <Image
                src="/images/compressed/webp/DarkGreenTruck.webp"
                fluid
                rounded
              /> */}
              <Image
                src="/images/compressed/webp/newTruckOrange.webp"
                fluid
                rounded
              />
            </Col>
          </Row>
          <div className="text-center mt-3" style={{ paddingTop: "1rem" }}>
            <Link to="/store-front" className="btn btn-primary">
              Browse Our Parts
            </Link>
          </div>
        </section>

        {/* Featured Restoration Section */}
        <section className="featured-restoration mt-5 mb-5">
          <h3 className="mb-5">Featured Restoration</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <Image
                src="/images/compressed/webp/LightGreenTruck.webp"
                fluid
                rounded
              />
            </Col>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Dive into the journey of restoring a vintage 1957 Chevrolet
                    truck. From rusted and worn out to gleaming and revving,
                    we've meticulously transformed this classic into a
                    masterpiece.
                    <br />
                    <br />
                  </p>
                  <p className="landing-page-text">
                    Our restoration projects are a testament to our expertise
                    and dedication. We ensure every vehicle is restored to its
                    original glory while incorporating modern amenities for a
                    seamless blend of the past and present.
                  </p>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaRegCheckCircle
                          style={{ marginRight: "0.25rem" }}
                          color="#d80607"
                        />
                        <span>Original Restoration</span>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaRegCheckCircle
                          style={{ marginRight: "0.25rem" }}
                          color="#d80607"
                        />
                        <span>Custom Solutions</span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaRegCheckCircle
                          style={{ marginRight: "0.25rem" }}
                          color="#d80607"
                        />
                        <span>Attention to Detail</span>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <FaRegCheckCircle
                          style={{ marginRight: "0.25rem" }}
                          color="#d80607"
                        />
                        <span>Customer Satisfaction</span>
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <div className="text-center mt-3" style={{ paddingTop: "1rem" }}>
            <Link to="/restoration" className="btn btn-primary">
              See Our Restoration Services
            </Link>
          </div>
        </section>

        {/* Featured Products Section */}
        <section className="featured-products mt-5 mb-5">
          <h3>Featured Products</h3>
          <LandingProducts />
          <div className="text-center mt-3">
            <Link to="/store" className="btn btn-primary">
              Shop Now
            </Link>
          </div>
        </section>

        <section className="merchandise mt-5 mb-5">
          <h2>Merchandise</h2>
          <Row className="align-items-center">
            <Col md={6}>
              <p className="landing-page-text">
                Looking for something special to show off your love for classic
                cars? Explore our{" "}
                <a
                  href="https://lugo-parts-restoration.square.site/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="landing-page-text"
                >
                  Merchandise
                </a>{" "}
                store for exclusive apparel, accessories, and more. From
                t-shirts to hats, we've got something for every enthusiast.
              </p>
              <Row className="no-padding">
                <Col xs={12} className="no-padding">
                  <div className="d-flex justify-content-start">
                    <div className="icon-text">
                      <FaTshirt size={20} />
                      <p>Apparel</p>
                    </div>
                    <div className="icon-text">
                      <FaHatCowboy size={20} />
                      <p>Hats</p>
                    </div>
                    <div className="icon-text">
                      <FaCar size={20} />
                      <p>Car Accessories</p>
                    </div>
                    <div className="icon-text">
                      <FaGift size={20} />
                      <p>Gifts</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="text-center mt-3 d-none d-md-block">
                <a
                  href="https://lugo-parts-restoration.square.site/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Shop Merchandise Now
                </a>
              </div>
            </Col>
            <Col md={6}>
              <Image
                src="/images/compressed/webp/MerchImage.webp"
                alt="Merchandise Image"
                fluid
                rounded
              />
            </Col>
          </Row>
          <Row className="mt-3 d-md-none">
            <Col className="text-center">
              <a
                href="https://lugo-parts-restoration.square.site/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                Shop Merchandise Now
              </a>
            </Col>
          </Row>
        </section>

        {/* Social Section */}
        <section className="social-media mt-5 mb-5">
          <h2 className="text-center">Stay Connected With Us</h2>
          <br />
          <p className="text-center landing-page-text">
            Follow us on social media for the latest updates, exclusive offers,
            and behind-the-scenes content.
          </p>
          <br />
          <br />
          <Row className="justify-content-center">
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://www.instagram.com/lugoparts_restoration/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={30} className="mx-2" />
              </a>
              <h4>Instagram</h4>
              <p className="landing-page-text">
                See our latest projects and get some restoration inspiration.
              </p>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://twitter.com/lugoparts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter size={30} className="mx-2" />
              </a>
              <h4>Twitter</h4>
              <p className="landing-page-text">
                Stay up-to-date with Lugo Parts & Restoration news and updates.
              </p>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://www.facebook.com/LugoParts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook size={30} className="mx-2" />
              </a>
              <h4>Facebook</h4>
              <p className="landing-page-text">Join our community!</p>
            </Col>
          </Row>
        </section>

        {/* ... other sections ... */}
      </Container>
    </>
  );
};

export default LandingScreen;
