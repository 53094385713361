import { apiSlice } from "./apiSlice";
import { ORDERS_URL, PAYPAL_URL } from "../constants";

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (order) => {
        const guestToken = localStorage.getItem("guestToken");
        let headers = {};
        if (guestToken) {
          headers = {
            Authorization: `Bearer ${guestToken}`,
          };
        }
        return {
          url: ORDERS_URL,
          method: "POST",
          body: { ...order },
          headers,
        };
      },
    }),
    getOrderDetails: builder.query({
      query: (orderId) => ({
        url: `${ORDERS_URL}/${orderId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    payOrder: builder.mutation({
      query: ({ orderId, details }) => ({
        url: `${ORDERS_URL}/${orderId}/pay`,
        method: "PUT",
        body: { ...details },
      }),
    }),
    getPayPalClientId: builder.query({
      query: () => ({
        url: PAYPAL_URL,
      }),
      keepUnusedDataFor: 5,
    }),
    getMyOrders: builder.query({
      query: ({ keyword, pageNumber }) => ({
        url: `${ORDERS_URL}/myorders`,
        params: {
          keyword,
          pageNumber,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getOrders: builder.query({
      query: ({ keyword, pageNumber }) => ({
        url: ORDERS_URL,
        params: {
          keyword,
          pageNumber,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    deliverOrder: builder.mutation({
      query: (orderId) => ({
        url: `${ORDERS_URL}/${orderId}/deliver`,
        method: "PUT",
      }),
    }),
    getTaxInfoByState: builder.query({
      query: ({ pageNumber }) => ({
        url: `${ORDERS_URL}/admin/tax`,
        params: {
          pageNumber,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getOrdersByState: builder.query({
      query: (state) => ({
        url: `${ORDERS_URL}/admin/tax/${state}`,
      }),
      keepUnusedDataFor: 5,
    }),
    applyCoupon: builder.mutation({
      query: ({ couponCode, cartItems, shippingAddress }) => ({
        // Ensure shippingAddress is included
        url: `/api/coupons/apply`,
        method: "POST",
        body: { couponCode, cartItems, shippingAddress },
      }),
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useGetOrderDetailsQuery,
  usePayOrderMutation,
  useGetPayPalClientIdQuery,
  useGetMyOrdersQuery,
  useGetOrdersQuery,
  useDeliverOrderMutation,
  useGetTaxInfoByStateQuery,
  useGetOrdersByStateQuery,
  useApplyCouponMutation,
} = ordersApiSlice;
