import React from "react";
import { useState, useEffect } from "react";
import { Table, Form, Button, Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import PaginateProfileOrders from "../components/PaginateProfileOrders";
import SearchBoxProfileOrders from "../components/SearchBoxProfileOrders";
import { FaTimes } from "react-icons/fa";
import { useProfileMutation } from "../slices/usersApiSlice";
import { setCredentials } from "../slices/authSlice";
import { useGetMyOrdersQuery } from "../slices/ordersApiSlice";

const ProfileScreen = () => {
  const { pageNumber, keyword } = useParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);

  const [updateProfile, { isLoading: loadingUpdateProfile }] =
    useProfileMutation();

  const { data, isLoading, error } = useGetMyOrdersQuery({
    keyword,
    pageNumber,
  });

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name);
      setEmail(userInfo.email);
    }
  }, [userInfo, userInfo.name, userInfo.email]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          name,
          email,
          password,
        }).unwrap();
        dispatch(setCredentials(res));
        toast.success("Profile updated successfully");

        // Clear password form fields
        setPassword("");
        setConfirmPassword("");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      <Meta
        title="User Profile | Lugo Parts & Restoration"
        description="Sign in to your Lugo Parts & Restoration account to place orders, manage your existing orders, and oversee your classic GMC truck restoration projects, as well as update your personal information."
        keywords="User Profile, Lugo Parts & Restoration, GMC Truck Restoration, Place Orders, Manage Orders, Personal Information, Update Profile, Classic Trucks, Vintage Vehicles, Automotive Restoration"
      />

      <Row>
        <Col md={3}>
          <h2>User Profile</h2>

          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="my-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="email" className="my-2">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <h2>Change Password</h2>
            <Form.Group controlId="password" className="my-2">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="confirmPassword" className="my-2">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Re-enter your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type="submit" variant="primary" className="my-2">
              Update
            </Button>
            {loadingUpdateProfile && <Loader />}
          </Form>
        </Col>
        <Col md={9}>
          <h2>My Orders</h2>

          {isLoading ? (
            <Loader />
          ) : error ? (
            <div>
              <Message variant="danger">
                {error?.data?.message || error.error}
              </Message>
              <Message variant="danger">
                Please go back and enter the full 24-Digit Order ID.{" "}
              </Message>
              <Link to="/profile" className="btn btn-light mt-2">
                Go Back
              </Link>
            </div>
          ) : (
            <>
              <SearchBoxProfileOrders />
              {keyword && (
                <Link to="/profile" className="btn btn-light mt-4 mb-4">
                  Go Back
                </Link>
              )}
              <Table striped hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th>ORDER ID</th>
                    <th>DATE</th>
                    <th>TOTAL</th>
                    <th>PAID</th>
                    <th>DELIVERED</th>
                    <th>DETAILS</th>
                  </tr>
                </thead>
                <tbody>
                  {data.orders.map((order) => (
                    <tr key={order._id}>
                      <td>{order._id}</td>
                      <td>
                        {new Date(order.createdAt)
                          .toLocaleString("en-US", {
                            timeZone: "America/Los_Angeles",
                            hour12: false,
                          })
                          .substring(0, 19) + " (PT)"}
                      </td>
                      <td>${order.totalPrice}</td>
                      <td>
                        {order.isPaid ? (
                          new Date(order.paidAt)
                            .toLocaleString("en-US", {
                              timeZone: "America/Los_Angeles",
                              hour12: false,
                            })
                            .substring(0, 19) + " (PT)"
                        ) : (
                          <FaTimes style={{ color: "red" }} />
                        )}
                      </td>

                      <td>
                        {order.isDelivered ? (
                          new Date(order.deliveredAt)
                            .toLocaleString("en-US", {
                              timeZone: "America/Los_Angeles",
                              hour12: false,
                            })
                            .substring(0, 19) + " (PT)"
                        ) : (
                          <FaTimes style={{ color: "red" }} />
                        )}
                      </td>

                      <td>
                        <LinkContainer to={`/order/${order._id}`}>
                          <Button className="btn-sm" variant="light">
                            Details
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <PaginateProfileOrders
                pages={data.pages}
                page={data.page}
                keyword={keyword ? keyword : ""}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProfileScreen;
