// couponsApiSlice.js
import { apiSlice } from "./apiSlice";
import { COUPONS_URL } from "../constants";

export const couponsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch all coupons
    getCoupons: builder.query({
      query: ({ pageNumber }) => ({
        url: COUPONS_URL,
        params: {
          pageNumber,
        },
      }),
      providesTags: ["Coupons"],
      keepUnusedDataFor: 5,
    }),
    // Get coupon by ID
    getCouponDetails: builder.query({
      query: (couponId) => `${COUPONS_URL}/${couponId}`,
    }),
    // Create a new coupon
    createCoupon: builder.mutation({
      query: (couponData) => ({
        url: COUPONS_URL,
        method: "POST",
        body: couponData,
      }),
      invalidatesTags: ["Coupons"],
    }),
    // Update an existing coupon
    updateCoupon: builder.mutation({
      query: ({ id, ...couponData }) => ({
        url: `${COUPONS_URL}/${id}`,
        method: "PUT",
        body: couponData,
      }),
      invalidatesTags: ["Coupons"],
    }),
    // Delete a coupon
    deleteCoupon: builder.mutation({
      query: (id) => ({
        url: `${COUPONS_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Coupons"],
    }),
  }),
});

export const {
  useGetCouponsQuery,
  useGetCouponDetailsQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
} = couponsApiSlice;
