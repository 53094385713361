import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaInstagram, FaTwitter, FaFacebook } from "react-icons/fa";
import "../assets/styles/Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Container>
        <Row>
          <Col className="text-center py-1">
            <p>
              &copy; {currentYear} Lugo Parts & Restoration, Inc. | 3240 Taylor
              Rd, Loomis, CA 95650
            </p>
          </Col>
        </Row>
        <Row className="text-center footer-row">
          <Col>
            <div className="footer-links-wrapper">
              <Link to="/about" className="footer-links">
                About
              </Link>
              <Link to="/restoration" className="footer-links">
                Restorations
              </Link>
              <Link to="/parts" className="footer-links">
                Parts
              </Link>
              <Link to="/store-front" className="footer-links">
                Store
              </Link>
              <Link to="/contactus" className="footer-links">
                Contact
              </Link>
              <Link to="/faq" className="footer-links">
                FAQ
              </Link>
              <Link to="/return-policy" className="footer-links">
                Return Policy
              </Link>
              <Link to="/privacy" className="footer-links">
                Privacy Policy
              </Link>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="text-center custom-padding">
            <a
              href="https://www.instagram.com/lugoparts_restoration/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={20} className="mx-2" />
            </a>
            <a
              href="https://twitter.com/lugoparts/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size={20} className="mx-2" />
            </a>
            <a
              href="https://www.facebook.com/LugoParts/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook size={20} className="mx-2" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
