import React from "react";
import { Row, Col, Image, Container, ListGroup } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import Meta from "../components/Meta";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import redMarker from "../assets/redMarker.png";

const AboutScreen = () => {
  const icon = new Icon({
    iconUrl: redMarker,
    iconRetinaUrl: redMarker,
    iconSize: [29, 35], // size of the icon in pixels
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <>
      <Meta
        title="About Us | Lugo Parts & Restoration"
        description="Welcome to Lugo Parts & Restoration, a family-owned business specializing in classic GMC truck restorations. Located in Northern California, we've been breathing life into vintage trucks since 2005, turning clients into family."
        keywords="About Us, Lugo Parts & Restoration, Family-Owned, Classic GMC Trucks, Restorations, Northern California, Vintage, Family, Journey, Legacy, Expertise, Genuine Connections, Automotive History, Clients, Tradition, Shared Passion"
      />

      <Container>
        {/* Our Journey and Legacy */}
        <section className="our-journey mt-5 mb-5">
          <h3 className="mb-5">Our Journey and Legacy</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Welcome to Lugo Parts and Restoration, where passion and
                    legacy converge to bring you the finest in classic truck
                    restoration. Founded by the dynamic duo, Omar and Stacy
                    Lugo, our family-owned and operated business is nestled in
                    the heart of Northern California.
                    <br />
                    <br />
                    Our journey began long before our official establishment in
                    2005, fueled by Omar and Stacy's shared love for preserving
                    the timeless beauty of classic trucks.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <Image
                src="/images/compressed/webp/AboutUs01.webp"
                fluid
                rounded
              />
            </Col>
          </Row>
        </section>

        {/* The Lugo Family Tradition */}
        <section className="family-tradition mt-5 mb-5">
          <h3 className="mb-5">The Lugo Family Tradition</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <Image src="/images/compressed/webp/LugoFam.webp" fluid rounded />
            </Col>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    What sets us apart is not just our expertise, but the
                    genuine connections we've forged with our cherished
                    customers over the years. What started as a venture driven
                    by a fervor for preserving automotive history has evolved
                    into a haven where clients have transformed into family.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Celebrating Our Shared Passion */}
        <section className="shared-passion mt-5 mb-5">
          <h3 className="mb-5" style={{ textAlign: "center" }}>
            Celebrating Our Shared Passion
          </h3>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Our foundation is built upon nurturing this family-like
                    relationship with our clients, enabling us to breathe life
                    into every project that comes our way. Join us in
                    celebrating not just classic trucks, but the stories,
                    memories, and shared passions that fuel our drive to make
                    every restoration project a testament to the spirit of our
                    beginnings.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* A Long Photo */}
        <section className="mt-5 mb-5">
          <Row>
            <Col md={12}>
              <Image
                src="/images/compressed/webp/LugoHero.webp"
                fluid
                rounded
              />
            </Col>
          </Row>
        </section>

        {/* Visit Our Store */}
        <section className="visit-store mt-5 mb-5">
          <h3 className="mb-5" style={{ textAlign: "center" }}>
            Visit Our Store
          </h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    We invite you to visit our shop located at:
                    <br />
                    <br />
                    <strong>3240 Taylor Rd, Loomis, CA 95650</strong>
                    <br />
                    <br />
                    Come and experience our passion for classic truck
                    restoration firsthand.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <MapContainer
                center={[38.8349, -121.1822]}
                zoom={15}
                style={{ height: "400px", width: "100%", borderRadius: "12px" }}
                dragging={false}
                zoomControl={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                touchZoom={false}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[38.8349, -121.1822]}
                  icon={icon}
                  draggable={false}
                >
                  <Popup>
                    Lugo Parts & Restoration, Inc. <br />
                    <a
                      href="https://goo.gl/maps/fv5XzFEWUxdH2Xao6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3240 Taylor Rd, Loomis, CA 95650
                    </a>
                  </Popup>
                </Marker>
              </MapContainer>
            </Col>
          </Row>
        </section>

        {/* Social Section */}
        <section className="social-media mt-5 mb-5">
          <h2 className="text-center">Stay Connected With Us</h2>
          <br />
          <p className="text-center landing-page-text">
            Follow us on social media for the latest updates, exclusive offers,
            and behind-the-scenes content.
          </p>
          <br />
          <br />
          <Row className="justify-content-center">
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://www.instagram.com/lugoparts_restoration/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={30} className="mx-2" />
              </a>
              <h4>Instagram</h4>
              <p className="landing-page-text">
                See our latest projects and get some restoration inspiration.
              </p>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://twitter.com/lugoparts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter size={30} className="mx-2" />
              </a>
              <h4>Twitter</h4>
              <p className="landing-page-text">
                Stay up-to-date with Lugo Parts & Restoration news and updates.
              </p>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://www.facebook.com/LugoParts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook size={30} className="mx-2" />
              </a>
              <h4>Facebook</h4>
              <p className="landing-page-text">Join our community!</p>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default AboutScreen;
