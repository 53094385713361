import React from "react";
import { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";

import Meta from "../components/Meta";

const ProudPartnersScreen = () => {
  useEffect(() => {
    const resetFonts = () => {
      document.body.style.setProperty(
        "font-family",
        '"Segoe UI", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif',
        "important"
      );
      document
        .querySelector(".proud-partners-container")
        .style.setProperty(
          "font-family",
          '"Segoe UI", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif',
          "important"
        );
    };

    const cidScript = document.createElement("script");
    cidScript.text = 'var cid = "Lugo Parts & Restoration";';
    document.body.appendChild(cidScript);

    const jcScript = document.createElement("script");
    jcScript.src = "https://www.jasperengines.com/files/js/jc.js";
    jcScript.onload = resetFonts; // Reset fonts after script loads
    document.body.appendChild(jcScript);

    // Also reset fonts after a delay to catch any delayed style applications
    const timeoutId = setTimeout(resetFonts, 1000);

    return () => {
      document.body.removeChild(cidScript);
      document.body.removeChild(jcScript);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <Meta
        title="Proud Partners | Lugo Parts & Restoration"
        description="Discover our proud partners at Lugo Parts & Restoration. We are proud to partner with these companies to bring you the best products and services."
        keywords="Proud Partners, Lugo Parts & Restoration, Partners, Products, Services"
      />
      <Container className="proud-partners-container">
        <h1 className="text-center">Proud Partners</h1>
        <section className="proud-partners-intro text-center mt-5 mb-5">
          <p className="landing-page-text">
            Welcome to our Proud Partners page! Here, you'll find a showcase of
            the valued companies and organizations that collaborate with Lugo
            Parts & Restoration. These partners share our commitment to
            preserving the legacy of classic 1955-59 Chevrolet and GMC trucks.
            Explore the trusted names below that help us provide quality
            products and services to our community. Together, we're keeping
            automotive history alive!
          </p>
        </section>

        <section className="jasper-engines mt-5 mb-5">
          <Card className="bg-gray-500">
            <Card.Body className="p-3 p-md-5">
              <h2 className="text-center mb-4">
                Jasper Engines & Transmissions
              </h2>
              <Row className="align-items-center mb-4">
                <Col md={12}>
                  <p className="landing-page-text">
                    We're proud to partner with Jasper Engines & Transmissions,
                    a leader in remanufactured products. Their commitment to
                    quality and reliability aligns perfectly with our mission to
                    provide the best for your classic truck restoration
                    projects.
                  </p>
                </Col>
              </Row>
              <div className="mt-4 overflow-hidden">
                <div id="jc" className="w-100"></div>
              </div>
            </Card.Body>
          </Card>
        </section>
        {/* OTHER PARTNERS DOWN HERE, ADD SECTION LIKE ABOVE BELOW */}
      </Container>
    </>
  );
};

export default ProudPartnersScreen;
