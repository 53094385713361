import React from "react";
import { Row, Col, Image, Container, ListGroup } from "react-bootstrap";
import Meta from "../components/Meta";
import GalleryCarousel from "../components/GalleryCarousel";

const GalleryScreen = () => {
  return (
    <>
      <Meta
        title="Gallery | Lugo Parts & Restoration"
        description="Explore the Lugo Parts & Restoration Gallery to witness our commitment to quality and craftsmanship. Discover the transformation of vintage GMC trucks into timeless masterpieces through our curated selection of completed projects."
        keywords="Gallery, Lugo Parts & Restoration, GMC Truck Restoration, Vintage Trucks, Timeless Masterpieces, Quality, Craftsmanship, Completed Projects, Transformation, Revival, Beauty, Unique Story, Curated Selection, Joy, Pride, Work"
      />

      <Container>
        {/* A Visual Journey Through Our Craftsmanship */}
        <section className="craftsmanship mt-5 mb-5">
          <h3 className="mb-5">A Visual Journey Through Our Craftsmanship</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Welcome to our gallery, a testament to our commitment to
                    quality and craftsmanship. Here, you can explore the
                    intricate details of our restoration projects and witness
                    the transformation of vintage trucks into timeless
                    masterpieces.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <Image
                src="/images/compressed/webp/Gallery06.webp"
                fluid
                rounded
              />
            </Col>
          </Row>
        </section>

        {/* Our Gallery */}
        <section className="our-gallery mt-5 mb-5">
          <h3 className="mb-5" style={{ textAlign: "center" }}>
            The Lugo Parts & Restoration Gallery
          </h3>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Below you'll find a curated selection of our completed
                    projects, each telling its own unique story of revival and
                    beauty. We invite you to scroll through and share in the joy
                    and pride we take in our work.
                  </p>
                  <br />
                </ListGroup.Item>
              </ListGroup>
              {/* Carousel Below */}
              <GalleryCarousel />
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default GalleryScreen;
