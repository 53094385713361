import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Product from "../components/Product";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";
import Meta from "../components/Meta";
import { useGetFilteredProductsQuery } from "../slices/productsApiSlice";

const UsedPartsStoreScreen = () => {
  const { pageNumber } = useParams();
  const { data, isLoading, error } = useGetFilteredProductsQuery({
    brand: "Used",
    pageNumber,
  });
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate("/store-front");
  };

  return (
    <>
      <Meta title="Coming Soon: Used Parts | Lugo Parts & Restoration" />
      <Row className="justify-content-left mt-4">
        <Col md={6} className="text-left">
          <Button onClick={goBackHandler} variant="light">
            Back to Store Front
          </Button>
        </Col>
      </Row>
      <Container className="mt-5 mb-5">
        <Row className="justify-content-center mb-5">
          {" "}
          {/* Added margin-bottom */}
          <Col md={6} className="text-center">
            <h1 className="mb-5">Coming Soon: Used Parts</h1>
            <Image
              src="/images/compressed/webp/usedPartsBoxNew.webp"
              alt="Coming Soon: Used Parts"
              fluid
            />
          </Col>
        </Row>

        {/* Product Listing and Messages */}
        <Row>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : data && data.products.length > 0 ? (
            <>
              {data.products.map((product) => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
              <Col xs={12}>
                <Paginate
                  pages={data.pages}
                  page={data.page}
                  keyword={""} // Update as needed
                />
              </Col>
            </>
          ) : (
            <Col xs={12}>
              <Message>No Used Parts found</Message>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default UsedPartsStoreScreen;
