import React from "react";
import { Row, Col, Container, ListGroup } from "react-bootstrap";
import Meta from "../components/Meta";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Meta
        title="Privacy Policy | Lugo Parts & Restoration"
        description="Learn about our privacy policy and how we handle your data to provide a seamless experience."
        keywords="Privacy Policy, Data Handling, Customer Privacy, Information Security"
      />

      <Container>
        {/* Privacy Policy Header */}
        <section className="privacy-policy-header mt-5 mb-5">
          <h3 className="mb-5">Customer Privacy Notice</h3>
          <p className="landing-page-text">
            Your privacy holds immense value to us. Our Privacy Notice aims to
            offer clear insights into our data handling practices in an easily
            comprehensible and navigable format. Please delve into the sections
            below to grasp how we gather, utilize, share, and protect your
            information to ensure you have a smooth and secure experience with
            us.
          </p>
        </section>

        {/* Information We Collect Section */}
        <section className="privacy-policy-section mt-5 mb-5">
          <h4>Information We Collect</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    We collect information to provide better services to our
                    customers:
                  </p>
                  <br />
                  <ul>
                    <li>Analytics data via Google Analytics 4</li>
                    <li>Cookie data for secure log-in</li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* How We Use Information Section */}
        <section className="privacy-policy-section mt-5 mb-5">
          <h4>How We Use Information</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    We use the information we collect to:
                  </p>
                  <br />
                  <ul>
                    <li>Analyze website traffic to improve our services</li>
                    <li>Ensure the security of our website</li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Information Sharing */}
        <section className="privacy-policy-section mt-5 mb-5">
          <h4>Information Sharing</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    We do not share personal information with companies,
                    organizations, or individuals outside of Lugo Parts &
                    Restoration unless one of the following circumstances
                    applies:
                  </p>
                  <br />
                  <ul>
                    <li>Analyze website traffic to improve our services</li>
                    <li>Ensure the security of our website</li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Cookies */}
        <section className="privacy-policy-section mt-5 mb-5">
          <h4>Cookies</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    We use cookies to ensure a secure log-in and provide a
                    better user experience.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Your Choices */}
        <section className="privacy-policy-section mt-5 mb-5">
          <h4>Your Choices</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    You can set your browser to refuse all cookies or to
                    indicate when a cookie is being sent. You may also submit a
                    Data Privacy Request.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Data Privacy Requests */}
        <section className="privacy-policy-section mt-5 mb-5">
          <h4>Data Privacy Requests</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    We empower you to have control over your personal data.
                    Depending on local laws, you may have rights to:
                  </p>
                  <br />
                  <ul>
                    <li>
                      Request access, corrections, deletion, or restrictions on
                      your data
                    </li>
                    <li>Object or withdraw consent to certain data uses</li>
                    <li>
                      Lodge a complaint with local data protection authorities
                    </li>
                  </ul>
                  <p className="landing-page-text">
                    Lugo Parts and Restoration enables you to exercise those
                    rights by:
                  </p>
                  <br />
                  <ul>
                    <li>
                      Emailing us at{" "}
                      <a href="mailto:lugoparts@yahoo.com">
                        lugoparts@yahoo.com
                      </a>{" "}
                      and submitting a data privacy request
                    </li>
                    <li>
                      Writing to us at Lugo Parts & Restoration, Attn: Legal –
                      Privacy, 3240 Taylor Road, Loomis CA 95650, United States.
                    </li>
                  </ul>
                  <p className="landing-page-text">
                    For any issue, we may request more information to verify
                    your identity or inform you if more time is needed. You can
                    refer unresolved complaints to your local regulator and
                    we'll provide relevant complaint avenues upon request.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Changes */}
        <section className="privacy-policy-section mt-5 mb-5">
          <h4>Changes</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Our Privacy Policy may change from time to time. We will
                    post any privacy policy changes on this page.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Contact Us Section */}
        <section className="privacy-policy-section mt-5 mb-5">
          <h4>Contact Us</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    For privacy questions or comments, to opt out from certain
                    services, or to submit a data privacy request, please
                    contact us:{" "}
                    <a href="mailto:lugoparts@yahoo.com">lugoparts@yahoo.com</a>
                    .
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default PrivacyPolicyScreen;
