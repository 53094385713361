import React from "react";
import { Table, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import PaginateAdminTaxList from "../../components/PaginateAdminTaxList";
import { useGetTaxInfoByStateQuery } from "../../slices/ordersApiSlice";

const TaxInfoListScreen = () => {
  const { pageNumber } = useParams();

  const { data, isLoading, error } = useGetTaxInfoByStateQuery({ pageNumber });

  return (
    <>
      <Meta
        title="Tax Information by State | Admin | Lugo Parts & Restoration"
        description="Admin panel for managing tax information at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>

      <h1>Tax Information by State</h1>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error.message || "An error occurred"}
        </Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>STATE</th>
                <th>TOTAL ORDERS</th>
                <th>TOTAL ITEM COST</th>
                <th>TOTAL TAX COLLECTED</th>
                <th>DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.taxInfoByState.map((taxInfo, index) => (
                  <tr key={index}>
                    <td>{taxInfo._id || "N/A"}</td>
                    <td>{taxInfo.totalOrders || "N/A"}</td>
                    <td>
                      $
                      {taxInfo.totalItemCosts
                        ? taxInfo.totalItemCosts.toFixed(2)
                        : "N/A"}
                    </td>
                    <td>
                      $
                      {taxInfo.totalTaxCollected
                        ? taxInfo.totalTaxCollected.toFixed(2)
                        : "N/A"}
                    </td>
                    <td>
                      {taxInfo._id && (
                        <Link to={`/admin/tax/${taxInfo._id}`}>
                          <Button variant="light" className="btn-sm">
                            Details
                          </Button>
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <PaginateAdminTaxList
            pages={data.pages}
            page={data.page}
            isAdmin={true}
          />
        </>
      )}
    </>
  );
};

export default TaxInfoListScreen;
