// GuestCheckoutScreen.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useCreateGuestUserMutation } from "../slices/guestsApiSlice";

const GuestCheckoutScreen = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [createGuestUser] = useCreateGuestUserMutation(); // Use the hook

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      // Create a guest user and get the token
      const response = await createGuestUser(email).unwrap();
      // Store the token and email in local storage
      localStorage.setItem("guestToken", response.token);
      localStorage.setItem("guestEmail", email);
      navigate("/shipping"); // Navigate to the next step
    } catch (error) {
      // Handle errors here, e.g., show an error message
      console.error("Error creating guest user:", error);
    }
  };

  return (
    <Form onSubmit={submitHandler}>
      <Form.Group controlId="email">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </Form.Group>
      <Button type="submit" variant="primary" className="mt-3">
        Proceed as Guest
      </Button>
    </Form>
  );
};

export default GuestCheckoutScreen;
