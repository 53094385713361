import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import FormContainer from "../../components/FormContainer";
import { toast } from "react-toastify";
import {
  useGetCouponDetailsQuery,
  useUpdateCouponMutation,
} from "../../slices/couponsApiSlice";

const CouponEditScreen = () => {
  const { id: couponId } = useParams();
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [isActive, setIsActive] = useState(true);

  const { data: coupon, isLoading, error } = useGetCouponDetailsQuery(couponId);
  const [updateCoupon, { isLoading: isLoadingUpdate }] =
    useUpdateCouponMutation();

  useEffect(() => {
    if (coupon) {
      setCode(coupon.code);
      setDiscountPercentage(coupon.discountPercentage);
      setIsActive(coupon.isActive);
    }
  }, [coupon]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateCoupon({
        id: couponId,
        code,
        discountPercentage,
        isActive,
      }).unwrap();
      toast.success("Coupon updated");
      navigate("/admin/couponlist");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Meta title="Edit Coupon | Admin" />
      <Link to="/admin/couponlist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Coupon</h1>
        {isLoadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error.data.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="code" className="my-2">
              <Form.Label>Coupon Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter coupon code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="discount" className="my-2">
              <Form.Label>Discount Percentage</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter discount percentage"
                value={discountPercentage}
                onChange={(e) => setDiscountPercentage(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="isActive" className="my-2">
              <Form.Check
                type="checkbox"
                label="Is Active"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            </Form.Group>

            <Button type="submit" variant="primary" className="my-2">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default CouponEditScreen;
