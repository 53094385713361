import React from "react";
import { Row, Col, Container, ListGroup } from "react-bootstrap";
import Meta from "../components/Meta";

const FAQScreen = () => {
  return (
    <>
      <Meta
        title="FAQ | Lugo Parts & Restoration"
        description="Find answers to frequently asked questions about our specialized GMC truck restoration services, parts, and customer policies. Get all the information you need for a seamless GMC restoration journey."
        keywords="FAQ, Frequently Asked Questions, GMC Truck Restoration, Services, Parts, Customer Policies, Information, Journey, Seamless, Vintage, Automotive, Quality, Professional, Reliable, Customer Service, Answers, Policies, Shopping"
      />

      <Container>
        {/* FAQ Header */}
        <section className="faq-header mt-5 mb-5">
          <h3 className="mb-5">Frequently Asked Questions</h3>
        </section>

        {/* What is Restoration? */}
        <section className="faq-section mt-5 mb-5">
          <h4>What is Restoration?</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Process used for classic, expensive, and antique automobiles
                    whereby the vehicle is restored to its original condition or
                    even better. Involves high-quality paint jobs, major engine
                    and transmission replacement, and body parts fabrication.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* What are “After Market” Parts? */}
        <section className="faq-section mt-5 mb-5">
          <h4>What are “After Market” Parts?</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Discount parts which are copied from factory original parts.
                    These parts are of lower quality but may be lower in price
                    by as much as one-third the original part’s price.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Where Do We Ship? */}
        <section className="faq-section mt-5 mb-5">
          <h4>Where Do We Ship?</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    We are committed to delivering your orders in a timely and
                    efficient manner. Our shipping services extend to all U.S.
                    states, with the notable exceptions of{" "}
                    <strong>Hawaii</strong> and <strong>Alaska</strong>.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Handling Fee Explanation */}
        <section className="faq-section mt-5 mb-5">
          <h4>Handling Fee Explanation</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    We strive to provide a seamless shopping experience for our
                    valued customers, which includes efficient processing and
                    handling of orders. Our handling fee is a modest charge that
                    contributes towards covering the costs associated with order
                    processing, handling, and fulfillment. This fee enables us
                    to maintain our high standards of service and ensure timely
                    delivery of your order. It covers:
                  </p>
                  <br />
                  <ul className="landing-page-list">
                    <li>
                      Order Processing: The systematic tracking, management, and
                      processing of your order from the moment it's placed to
                      the point it's prepared for shipment.
                    </li>
                    <li>
                      Employee Compensation: Fair compensation for our dedicated
                      team who work diligently to ensure your order is processed
                      accurately and expediently.
                    </li>
                    <li>
                      Operational Expenses: The necessary expenses incurred to
                      maintain our order processing system, which includes
                      technology, utilities, and facilities that support order
                      fulfillment.
                    </li>
                  </ul>
                  <p className="landing-page-text">
                    This modest fee is instrumental in helping us uphold our
                    commitment to delivering exceptional service and
                    satisfaction to our customers. Thank you for your
                    understanding and continued patronage.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Industry Terms */}
        <section className="faq-section mt-5 mb-5">
          <h4>
            Some industry terms you might hear during the restoration process:
          </h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <ul className="landing-page-list">
                    <li>
                      Blending: Process whereby paint is gradually lightened or
                      darkened on a repair vehicle to eliminate spottiness.
                    </li>
                    <li>
                      Bondo: Compound applied to the vehicle to smooth out and
                      fill the exterior so that paint may be applied.
                    </li>
                    <li>
                      Chassis: The frame of the vehicle that supports the body
                      and other key components.
                    </li>
                    <li>
                      Clear Coat: Finish added after paint job to give the
                      vehicle a high luster look and protection.
                    </li>
                    <li>
                      Detailing: After body repair, the vehicle may be enhanced
                      by washing, waxing, interior cleaning, shampoo treatments,
                      etc.
                    </li>
                    <li>
                      Drivetrain: The system that connects the transmission to
                      the drive axles.
                    </li>
                    <li>
                      Estimate: Process whereby the insurance company or the
                      auto body shops project the cost of the parts, labor, and
                      other supplies needed to repair a vehicle.
                    </li>
                    <li>
                      Fabrication: The process of building custom parts for a
                      vehicle.
                    </li>
                    <li>
                      Masking: Protection of glass, chrome, and other parts when
                      the vehicle is being painted.
                    </li>
                    <li>
                      Mechanics Lien: A filing with the Department of Motor
                      Vehicles on vehicles with unpaid charges for repairs
                      and/or storage.
                    </li>
                    <li>
                      Overspray: The spray or mist of paint or primer that
                      settles on parts of the vehicle other than those intended
                      to be sprayed.
                    </li>
                    <li>
                      Panels: General term to describe body components like side
                      panels, quarter panels, etc.
                    </li>
                    <li>Pin Striping: Specialized painting or decaling.</li>
                    <li>
                      Primers: Chemicals used to coat the metal surface of the
                      vehicle to avoid rust and to fill in scratches.
                    </li>
                    <li>
                      Repair Order: Document which usually contains the charges
                      for parts, labor, and other costs.
                    </li>
                    <li>
                      Restomod: A blend of old and new technology to create the
                      best of both worlds, combining classic styling with modern
                      convenience and performance.
                    </li>
                    <li>
                      Restoration: Process used for classic, expensive, and
                      antique automobiles whereby the vehicle is restored to its
                      original condition or even better.
                    </li>
                    <li>
                      Shot Blasting: A method used to clean, strengthen, or
                      polish metal surfaces using a high-speed stream of
                      abrasive material.
                    </li>
                    <li>
                      Shroud: Cover for many parts – for example, radiator
                      shroud.
                    </li>
                    <li>
                      Skin: That is “door skin.” Refers to the visible metal
                      covering a constructed component.
                    </li>
                    <li>
                      Tear Down: Removal of surface damage to assess total
                      repair needed.
                    </li>
                    <li>
                      Undercoat: Coating given to the bottom of the vehicle to
                      protect from rust and corrosion.
                    </li>
                    <li>
                      Warranty Work: Additional work performed after the job has
                      been completed and paid for.
                    </li>
                    <li>
                      Wetblocking: Sanding done after primer is applied so that
                      paint can be applied.
                    </li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default FAQScreen;
