import React from "react";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import { useParams, Link } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Paginate from "../../components/Paginate";
import Meta from "../../components/Meta";
import { toast } from "react-toastify";
import SearchBoxProductlistAdmin from "../../components/SearchBoxProductlistAdmin";
import {
  useGetProductsQuery,
  useCreateProductMutation,
  useDeleteProductMutation,
} from "../../slices/productsApiSlice";

const formatYears = (yearsArray) => {
  if (yearsArray.length === 1) {
    return yearsArray[0];
  }
  const sortedYears = [...yearsArray].sort((a, b) => a - b);
  let start = sortedYears[0];
  let end = sortedYears[0];
  let ranges = [];

  for (let i = 1; i < sortedYears.length; i++) {
    if (sortedYears[i] - end === 1) {
      end = sortedYears[i];
    } else {
      ranges.push(start === end ? `${start}` : `${start}-${end}`);
      start = end = sortedYears[i];
    }
  }
  ranges.push(start === end ? `${start}` : `${start}-${end}`);
  return ranges.join(", ");
};

const ProductListScreen = () => {
  const { keyword, pageNumber } = useParams();
  const { data, isLoading, error, refetch } = useGetProductsQuery({
    keyword,
    pageNumber,
  });

  const [createProduct, { isLoading: loadingCreate }] =
    useCreateProductMutation();

  const [deleteProduct, { isLoading: loadingDelete }] =
    useDeleteProductMutation();

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await deleteProduct(id);
        toast.success("Product deleted");
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const navigate = useNavigate();

  const createProductHandler = async () => {
    if (window.confirm("Are you sure you want to create a new product?")) {
      try {
        const { data } = await createProduct();
        const newProductId = data._id;
        navigate(`/admin/product/${newProductId}/edit`);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      <Meta
        title="Product List | Admin | Lugo Parts & Restoration"
        description="Admin panel for managing products at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>

      <Row className="align-items-center">
        <Col>
          <h1>Products</h1>
        </Col>
        <Col className="text-end">
          <Button className="btn-sm m-3" onClick={createProductHandler}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <FaPlus /> &nbsp;Create a New Product
            </span>{" "}
          </Button>
        </Col>
      </Row>

      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error.data.message}</Message>
      ) : (
        <>
          <SearchBoxProductlistAdmin />
          {keyword && (
            <Link to="/admin/productlist" className="btn btn-light mt-4 mb-4">
              Go Back
            </Link>
          )}
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>PRICE</th>
                <th>VENDOR PART NUMBER</th>
                <th>LUGO PART NUMBER</th>
                <th>VENDOR</th>
                <th>BRAND</th>
                <th>CATEGORY</th>
                <th>YEAR</th>
                <th>QUANTITY</th>
                <th>EDIT/DELETE</th>
              </tr>
            </thead>
            <tbody>
              {data.products.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>${product.price}</td>
                  <td>{product.category}</td>
                  <td>{product.lugoPart}</td>
                  <td>{product.vendor}</td>
                  <td>{product.brand}</td>
                  <td>{product.partType}</td>
                  <td>{formatYears(product.year)}</td>
                  <td>{product.countInStock}</td>
                  <td>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant="light" className="btn-sm mx-2">
                        <FaEdit />
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <FaTrash style={{ color: "white" }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Paginate
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ""}
            isAdmin={true}
          />
        </>
      )}
    </>
  );
};

export default ProductListScreen;
