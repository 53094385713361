import React from "react";
import { Row, Col, Container, ListGroup } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import Meta from "../components/Meta";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import redMarker from "../assets/redMarker.png";

const ContactUsScreen = () => {
  const icon = new Icon({
    iconUrl: redMarker,
    iconRetinaUrl: redMarker,
    iconSize: [29, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <>
      <Meta
        title="Contact Us | Lugo Parts & Restoration"
        description="Reach out to us for any inquiries, feedback, or support related to classic GMC truck restorations. Our team of experts is always available to assist you."
        keywords="Contact Us, Inquiries, Feedback, Support, Classic GMC Trucks, Restorations, Team, Experts, Assist, Vintage, Automotive, Quality, Professional, Reliable, Customer Service, Email, Phone, Address, Connect, Help"
      />

      <Container>
        {/* Contact Information */}
        <section className="contact-info mt-5 mb-5">
          <h3 className="mb-5">Contact Us</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    <strong>Lugo Parts & Restoration</strong>
                    <br />
                    3240 Taylor Road
                    <br />
                    Loomis, CA 95650
                    <br />
                    <br />
                    Email:{" "}
                    <a href="mailto:lugoparts@yahoo.com">lugoparts@yahoo.com</a>
                    <br />
                    <br />
                    Phone: <a href="tel:+19166520840">916-652-0840</a>
                    <br />
                    <br />
                    Extensions in our phone system:
                    <br />
                    <br />
                    <ul className="hashtag-list">
                      <li>#1 Location and Hours</li>
                      <li>#2 New Restoration Customers</li>
                      <li>#3 Current Customers</li>
                      <li>#4 Parts Sales</li>
                      <li>#5 Questions</li>
                    </ul>
                    <br />
                    <br />
                    Business hours: <strong>Mon-Fri</strong> 8am-4:30pm
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <MapContainer
                center={[38.8349, -121.1822]}
                zoom={15}
                style={{ height: "400px", width: "100%", borderRadius: "12px" }}
                dragging={false}
                zoomControl={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                touchZoom={false}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker
                  position={[38.8349, -121.1822]}
                  icon={icon}
                  draggable={false}
                >
                  <Popup>
                    Lugo Parts & Restoration, Inc. <br />
                    <a
                      href="https://goo.gl/maps/fv5XzFEWUxdH2Xao6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3240 Taylor Rd, Loomis, CA 95650
                    </a>
                  </Popup>
                </Marker>
              </MapContainer>
            </Col>
          </Row>
        </section>
        {/* Social Section */}
        <section className="social-media mt-5 mb-5">
          <h2 className="text-center">Stay Connected With Us</h2>
          <br />
          <p className="text-center landing-page-text">
            Follow us on social media for the latest updates, exclusive offers,
            and behind-the-scenes content.
          </p>
          <br />
          <br />
          <Row className="justify-content-center">
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://www.instagram.com/lugoparts_restoration/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={30} className="mx-2" />
              </a>
              <h4>Instagram</h4>
              <p className="landing-page-text">
                See our latest projects and get some restoration inspiration.
              </p>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://twitter.com/lugoparts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter size={30} className="mx-2" />
              </a>
              <h4>Twitter</h4>
              <p className="landing-page-text">
                Stay up-to-date with Lugo Parts & Restoration news and updates.
              </p>
            </Col>
            <Col xs={12} md={4} className="text-center">
              <a
                href="https://www.facebook.com/LugoParts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook size={30} className="mx-2" />
              </a>
              <h4>Facebook</h4>
              <p className="landing-page-text">Join our community!</p>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default ContactUsScreen;
