import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const GuestRoute = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const guestEmail = localStorage.getItem("guestEmail");

  // Allow access if the user is logged in or if a guest email exists
  return userInfo || guestEmail ? <Outlet /> : <Navigate to="/login" replace />;
};

export default GuestRoute;
