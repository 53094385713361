import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Button from "react-bootstrap/Button";
import "../assets/styles/material.custom.css";

const AdvancedFilter = ({ filters, setFilters }) => {
  const { brand, partType, yearRange } = filters;

  const handleBrandChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      brand: event.target.checked
        ? [...prevFilters.brand, event.target.name]
        : prevFilters.brand.filter((item) => item !== event.target.name),
    }));
  };

  const partTypes = [
    "Bed Floor Pans",
    "Bed Moldings",
    "Bed Panels",
    "Bed Reinforcement",
    "Body Shims",
    "Bumper & Guards",
    "Bumper Brackets",
    "Bumper Fillers",
    "Cab Components",
    "Cab Corners",
    "Cab Moldings",
    "Cowl Panels",
    "Dash Bezels & Appliques",
    "Dash Panels & Components",
    "Door Accessories",
    "Door Frame Panels & Cowls",
    "Door Locks",
    "Door Sill Scuff Plates",
    "Door Trims & Arm Rests",
    "Doors & Hardware",
    "Drip Rail Moldings",
    "Emblem",
    "Engine Compartment",
    "Exterior Accessories",
    "Exterior Electrical & Components",
    "Exterior Mirror Arms",
    "Exterior Mirrors",
    "Firewalls",
    "Foot Well Panels",
    "Front Fenders & Components",
    "Fuel Senders",
    "Fuel Tank Filler Kits",
    "Fuel Tank Straps",
    "Fuel Tanks",
    "Glove Box Locks",
    "Grille Moldings",
    "Grilles & Components",
    "Heating / Cooling",
    "Hood Latch Panels",
    "Hood Moldings",
    "Hoods & Components",
    "Horns",
    "Inner Fender / Wheelhouses",
    "Ignition & Door Kits",
    "Ignition Locks",
    "Ignition, Door & Glove Box Kits",
    "Interior Accessories",
    "Interior Electrical",
    "License Panel & Components",
    "Magnum Alloy Wheels",
    "Pedal & Shifter Components",
    "Quarter Trim",
    "Radiator Overflow Cap",
    "Radiator Supports",
    "Rear Fenders",
    "Rear View Mirrors & Brackets",
    "Rocker Box",
    "Rocker Box Reinforcement Panel",
    "Rocker Panels",
    "Roof Panels & Components",
    "Seat Adjustment Handle",
    "Seat Belts",
    "Seats & Components",
    "Steering Wheels",
    "Stone Deflectors / Roll Pans",
    "Sun Visor Bracket",
    "Sun Visor Pad",
    "Suspension & Steering",
    "Tailgate",
    "Tailgate Components",
    "Tailgate Molding",
    "Wheel Opening Moldings",
    "Windshield Fillers",
    "Windshield Moldings",
    "Wiper Motor Transmission Arms",
  ];

  const handlePartTypeChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      partType: event.target.checked
        ? [...prevFilters.partType, event.target.name]
        : prevFilters.partType.filter((item) => item !== event.target.name),
    }));
  };

  const handleYearChange = (event) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      yearRange: event.target.checked
        ? [...prevFilters.yearRange, event.target.name] // Add the range string
        : prevFilters.yearRange.filter((item) => item !== event.target.name), // Remove the range string
    }));
  };

  const yearRanges = [
    "1947-1954",
    "1955-1959",
    "1960-1966",
    "1967-1972",
    "1973-1987",
    "1988-1998",
  ];

  const [showFilter, setShowFilter] = useState(window.innerWidth > 768);

  useEffect(() => {
    // Function to check if the device is mobile
    const isMobileDevice = () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    };

    const handleResize = () => {
      // Only update the state if the device is not mobile
      if (!isMobileDevice()) {
        setShowFilter(window.innerWidth > 768);
      }
    };

    window.addEventListener("resize", handleResize);

    // Set initial state based on window width
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [expanded, setExpanded] = useState({
    brand: window.innerWidth > 768,
    category: window.innerWidth > 768,
    yearRange: window.innerWidth > 768,
  });

  const handleToggleExpand = (category) => (event) => {
    event.stopPropagation();
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [category]: !prevExpanded[category],
    }));
  };

  const resetFiltersAndGoBack = () => {
    setFilters({
      brand: [],
      partType: [],
      yearRange: [],
    });
  };

  return (
    <Box className="filter-section">
      <Button
        className="btn btn-primary custom-btn-primary filter-toggle-button"
        onClick={() => setShowFilter(!showFilter)}
      >
        {showFilter ? "Hide Filter" : "Show Filter"}
      </Button>
      <Collapse in={showFilter}>
        <Typography
          variant="h5"
          className="typoFontColor"
          style={{ padding: "0.75rem 0" }}
        >
          Filter
        </Typography>
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel
            component="legend"
            onClick={handleToggleExpand("yearRange")}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#FFFFFF",
              marginBottom: "0.5rem",
            }}
          >
            <IconButton
              edge="end"
              size="small"
              onClick={handleToggleExpand("yearRange")}
              sx={{ color: "#D80607" }}
            >
              {expanded.yearRange ? (
                <ExpandLessIcon /> // Use ExpandLessIcon when expanded
              ) : (
                <ExpandMoreIcon /> // Use ExpandMoreIcon when collapsed
              )}
            </IconButton>
            Year Range
          </FormLabel>
          <Collapse in={expanded.yearRange}>
            <FormGroup>
              {yearRanges.map((range) => (
                <FormControlLabel
                  key={range}
                  className="custom-form-control-label"
                  control={
                    <Checkbox
                      checked={yearRange.includes(range)}
                      onChange={handleYearChange}
                      name={range}
                      sx={{ color: "#D80607" }}
                    />
                  }
                  label={range}
                />
              ))}
            </FormGroup>
          </Collapse>
        </FormControl>
        {/* Commented out the Brand filter UI just for now until brand update */}
        {/* <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel
            component="legend"
            onClick={handleToggleExpand("brand")}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#FFFFFF",
            }}
          >
            <IconButton
              edge="end"
              size="small"
              onClick={handleToggleExpand("brand")}
              sx={{ color: "#D80607" }}
            >
              {expanded.brand ? (
                <ExpandLessIcon /> // Use ExpandLessIcon when expanded
              ) : (
                <ExpandMoreIcon /> // Use ExpandMoreIcon when collapsed
              )}
            </IconButton>
            Brand
          </FormLabel> */}

        {/* <Collapse in={expanded.brand}>
            <FormGroup>
              <FormControlLabel
                className="custom-form-control-label"
                control={
                  <Checkbox
                    checked={brand.includes("Chevrolet")}
                    onChange={handleBrandChange}
                    name="Chevrolet"
                    sx={{ color: "#D80607" }}
                  />
                }
                label="Chevrolet"
              />
              <FormControlLabel
                className="custom-form-control-label"
                control={
                  <Checkbox
                    checked={brand.includes("GMC")}
                    onChange={handleBrandChange}
                    name="GMC"
                    sx={{ color: "#D80607" }}
                  />
                }
                label="GMC"
              />
              <FormControlLabel
                className="custom-form-control-label"
                control={
                  <Checkbox
                    checked={brand.includes("GM Aftermarket")}
                    onChange={handleBrandChange}
                    name="GM Aftermarket"
                    sx={{ color: "#D80607" }}
                  />
                }
                label="GM Aftermarket"
              />
            </FormGroup>
          </Collapse> */}
        {/* </FormControl> */}
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel
            component="legend"
            onClick={handleToggleExpand("category")}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#FFFFFF",
              marginBottom: "0.5rem",
            }}
          >
            <IconButton
              edge="end"
              size="small"
              onClick={handleToggleExpand("category")}
              sx={{ color: "#D80607" }}
            >
              {expanded.category ? (
                <ExpandLessIcon /> // Use ExpandLessIcon when expanded
              ) : (
                <ExpandMoreIcon /> // Use ExpandMoreIcon when collapsed
              )}
            </IconButton>
            Category
          </FormLabel>
          <Collapse in={expanded.category}>
            <FormGroup>
              {partTypes.map((type) => (
                <FormControlLabel
                  key={type}
                  className="custom-form-control-label"
                  control={
                    <Checkbox
                      checked={partType.includes(type)}
                      onChange={handlePartTypeChange}
                      name={type}
                      sx={{ color: "#D80607" }}
                    />
                  }
                  label={type}
                />
              ))}
            </FormGroup>
          </Collapse>
        </FormControl>

        <Link
          to="/store"
          className="btn btn-light mb-4"
          onClick={resetFiltersAndGoBack}
        >
          Reset Filter
        </Link>
      </Collapse>
    </Box>
  );
};

export default AdvancedFilter;
