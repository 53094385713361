import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./store";
// import 'bootstrap/dist/css/bootstrap.min.css'; comment out to use custom
import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import GuestRoute from "./components/GuestRoute";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import ProfileScreen from "./screens/ProfileScreen";
import OrderListScreen from "./screens/admin/OrderListScreen";
import ProductListScreen from "./screens/admin/ProductListScreen";
import ProductEditScreen from "./screens/admin/ProductEditScreen";
import UserListScreen from "./screens/admin/UserListScreen";
import UserEditScreen from "./screens/admin/UserEditScreen";
import LandingScreen from "./screens/LandingScreen";
import AboutScreen from "./screens/AboutScreen";
import RestorationScreen from "./screens/RestorationScreen";
import PartScreen from "./screens/PartScreen";
import CareerScreen from "./screens/CareerScreen";
import GalleryScreen from "./screens/GalleryScreen";
import RestorationListScreen from "./screens/admin/RestorationListScreen";
import RestorationDetailsScreen from "./screens/admin/RestorationDetailsScreen";
import PartListScreen from "./screens/admin/PartListScreen";
import PartDetailsScreen from "./screens/admin/PartDetailsScreen";
import CareerListScreen from "./screens/admin/CareerListScreen";
import CareerDetailsScreen from "./screens/admin/CareerDetailsScreen";
import ReturnPolicyScreen from "./screens/ReturnPolicyScreen";
import FAQScreen from "./screens/FAQScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import TaxInfoListScreen from "./screens/admin/TaxInfoListScreen";
import StateTaxDetailsScreen from "./screens/admin/StateTaxDetailsScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import EmailVerificationScreen from "./screens/EmailVerificationScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import CouponListScreen from "./screens/admin/CouponListScreen";
import CouponEditScreen from "./screens/admin/CouponEditScreen";
import GuestCheckoutScreen from "./screens/GuestCheckoutScreen";
import StoreFrontScreen from "./screens/StoreFrontScreen";
import NapcoStoreScreen from "./screens/NapcoStoreScreen";
import UsedPartsStoreScreen from "./screens/UsedPartsStoreScreen";
import ProudPartnersScreen from "./screens/ProudPartnersScreen";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<LandingScreen />} />
      <Route path="/store" element={<HomeScreen />} />
      <Route path="/store/search/:keyword" element={<HomeScreen />} />
      <Route path="/store/page/:pageNumber" element={<HomeScreen />} />
      <Route
        path="/store/search/:keyword/page/:pageNumber"
        element={<HomeScreen />}
      />
      <Route path="/store-front" element={<StoreFrontScreen />} />
      <Route path="/napco-store" element={<NapcoStoreScreen />} />
      <Route path="/used-store" element={<UsedPartsStoreScreen />} />
      <Route path="/restoration" element={<RestorationScreen />} />
      <Route path="/parts" element={<PartScreen />} />
      <Route path="/gallery" element={<GalleryScreen />} />
      <Route path="/about" element={<AboutScreen />} />
      <Route path="/career" element={<CareerScreen />} />
      <Route path="/return-policy" element={<ReturnPolicyScreen />} />
      <Route path="/faq" element={<FAQScreen />} />
      <Route path="/contactus" element={<ContactUsScreen />} />
      <Route path="/proud-partners" element={<ProudPartnersScreen />} />
      <Route path="/privacy" element={<PrivacyPolicyScreen />} />
      <Route path="/product/:id" element={<ProductScreen />} />
      <Route path="/cart" element={<CartScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/guest-checkout" element={<GuestCheckoutScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route
        path="/verify-email/:token"
        element={<EmailVerificationScreen />}
      />
      <Route
        path="/password-reset-request"
        element={<ForgotPasswordScreen />}
      />
      <Route path="/password-reset/:token" element={<ResetPasswordScreen />} />

      <Route path="" element={<GuestRoute />}>
        <Route path="/shipping" element={<ShippingScreen />} />
        <Route path="/payment" element={<PaymentScreen />} />
        <Route path="/placeorder" element={<PlaceOrderScreen />} />
        <Route path="/order/:id" element={<OrderScreen />} />
      </Route>

      <Route path="" element={<PrivateRoute />}>
        <Route path="/shipping" element={<ShippingScreen />} />
        <Route path="/payment" element={<PaymentScreen />} />
        <Route path="/placeorder" element={<PlaceOrderScreen />} />
        <Route path="/order/:id" element={<OrderScreen />} />
        <Route
          path="/profile/orders/search/:keyword"
          element={<ProfileScreen />}
        />
        <Route path="/profile/orders/:pageNumber" element={<ProfileScreen />} />
        <Route
          path="/profile/orders/search/:keyword/page/:pageNumber"
          element={<ProfileScreen />}
        />
        <Route path="/profile" element={<ProfileScreen />} />
      </Route>

      <Route path="" element={<AdminRoute />}>
        <Route
          path="/admin/orderlist/search/:keyword"
          element={<OrderListScreen />}
        />
        <Route
          path="/admin/orderlist/page/:pageNumber"
          element={<OrderListScreen />}
        />
        <Route
          path="/admin/orderlist/search/:keyword/page/:pageNumber"
          element={<OrderListScreen />}
        />

        <Route path="/admin/orderlist" element={<OrderListScreen />} />

        <Route
          path="/admin/productlist/search/:keyword"
          element={<ProductListScreen />}
        />
        <Route
          path="/admin/productlist/page/:pageNumber"
          element={<ProductListScreen />}
        />
        <Route
          path="/admin/productlist/search/:keyword/page/:pageNumber"
          element={<ProductListScreen />}
        />
        <Route path="/admin/productlist" element={<ProductListScreen />} />

        <Route path="/admin/product/:id/edit" element={<ProductEditScreen />} />

        <Route
          path="/admin/userlist/search/:keyword"
          element={<UserListScreen />}
        />
        <Route
          path="/admin/userlist/search/:keyword/page/:pageNumber"
          element={<UserListScreen />}
        />
        <Route path="/admin/userlist" element={<UserListScreen />} />
        <Route
          path="/admin/userlist/page/:pageNumber"
          element={<UserListScreen />}
        />

        <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />

        <Route
          path="/admin/restorationlist/page/:pageNumber"
          element={<RestorationListScreen />}
        />

        <Route
          path="/admin/restorationlist"
          element={<RestorationListScreen />}
        />
        <Route
          path="/admin/restoration/:id"
          element={<RestorationDetailsScreen />}
        />

        <Route
          path="/admin/partlist/page/:pageNumber"
          element={<PartListScreen />}
        />

        <Route path="/admin/partlist" element={<PartListScreen />} />
        <Route path="/admin/part/:id" element={<PartDetailsScreen />} />

        <Route
          path="/admin/careerlist/page/:pageNumber"
          element={<CareerListScreen />}
        />
        <Route path="/admin/careerlist" element={<CareerListScreen />} />
        <Route path="/admin/career/:id" element={<CareerDetailsScreen />} />

        <Route
          path="/admin/tax/page/:pageNumber"
          element={<TaxInfoListScreen />}
        />
        <Route path="/admin/tax" element={<TaxInfoListScreen />} />

        <Route path="/admin/tax/:state" element={<StateTaxDetailsScreen />} />

        <Route path="/admin/couponlist" element={<CouponListScreen />} />
        <Route
          path="/admin/couponlist/:id/edit"
          element={<CouponEditScreen />}
        />
      </Route>
      <Route path="*" element={<NotFoundScreen />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PayPalScriptProvider deferLoading={true}>
          <RouterProvider router={router} />
        </PayPalScriptProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
