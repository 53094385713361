import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useVerifyEmailMutation } from "../slices/usersApiSlice";
import { useDispatch } from "react-redux";
import { verificationEmail } from "../slices/authSlice"; // import the verificationEmail action
import Loader from "../components/Loader";
import Message from "../components/Message";
import { toast } from "react-toastify";

const EmailVerificationScreen = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verifyEmail, { isLoading, isSuccess, isError, error }] =
    useVerifyEmailMutation();

  useEffect(() => {
    let isActive = true;

    if (token && isActive) {
      verifyEmail(token)
        .unwrap()
        .then((response) => {
          if (isActive) {
            toast.success("Email successfully verified!");
            // Dispatch the verificationEmail action
            dispatch(verificationEmail());
            navigate("/login");
          }
        })
        .catch((err) => {
          if (isActive) {
            toast.error(
              err?.data?.message ||
                "Failed to verify email. Please try again or contact support"
            );
            navigate("/");
          }
        });
    }

    return () => {
      isActive = false;
    };
  }, [token, verifyEmail, navigate, dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  if (isSuccess) {
    return (
      <Message variant="success">Email has been successfully verified!</Message>
    );
  }

  if (isError) {
    console.log("Verification Error: ", error);
    return (
      <Message variant="danger">
        Error verifying email. Please try again or contact support.
      </Message>
    );
  }

  return null; //return null
};

export default EmailVerificationScreen;
