import React from "react";
import { useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { FaDownload } from "react-icons/fa"; // For download button
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import { useGetCareerByIdQuery } from "../../slices/submissionApiSlice";

const CareerDetailsScreen = () => {
  const { id } = useParams();
  const { data: career, isLoading, error } = useGetCareerByIdQuery(id);

  const downloadFile = (fileUrl, fileName) => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = fileName;
        alink.click();
      });
  };

  return (
    <>
      <Meta
        title="Career Application Details | Admin | Lugo Parts & Restoration"
        description="Admin panel for viewing details of career opportunities at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Card>
          <Card.Header>
            <h2>Career Application Details</h2>
          </Card.Header>
          <Card.Body>
            <h3>ID: {career._id}</h3>
            <h3>Name: {career.name}</h3>
            <h3>Email: {career.email}</h3>
            <h3>Phone: {career.phone}</h3>
            <h3>Job Category: {career.jobCategory}</h3>
            <h3>
              Submitted On:{" "}
              {new Date(career.createdAt)
                .toLocaleString("en-US", {
                  timeZone: "America/Los_Angeles", // Pacific Time (PT)
                  hour12: false,
                })
                .substring(0, 19) + " (PT)"}
            </h3>
            {career.coverLetter && (
              <Button
                variant="success"
                className="me-2"
                onClick={() =>
                  downloadFile(career.coverLetter, "careers-coverLetter")
                }
              >
                <FaDownload /> Download Cover Letter
              </Button>
            )}
            {career.resume && (
              <Button
                variant="success"
                onClick={() => downloadFile(career.resume, "careers-resume")}
              >
                <FaDownload /> Download Resume
              </Button>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default CareerDetailsScreen;
