import React from "react";
import { Table, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Meta from "../../components/Meta";
import { useGetOrdersByStateQuery } from "../../slices/ordersApiSlice";

// Function to calculate summary data based on ordersData
const calculateSummary = (orders) => {
  let totalOrders = 0;
  let totalItemCosts = 0;
  let totalTaxCollected = 0;

  orders.forEach((order) => {
    totalOrders += 1;
    totalItemCosts += order.itemsPrice;
    totalTaxCollected += order.taxPrice;
  });

  return {
    totalOrders,
    totalItemCosts,
    totalTaxCollected,
  };
};

const StateTaxDetailsScreen = () => {
  const { state } = useParams();
  const {
    data: ordersData,
    isLoading: ordersLoading,
    error: ordersError,
  } = useGetOrdersByStateQuery(state);

  // Calculate summary data
  const summaryData = ordersData ? calculateSummary(ordersData) : null;

  return (
    <>
      <Meta
        title={`${state} Tax Details | Admin | Lugo Parts & Restoration`}
        description="Admin panel for viewing state-specific tax details at Lugo Parts & Restoration."
      >
        <meta name="robots" content="noindex, nofollow" />
      </Meta>
      <h1>{state} Tax Details</h1>
      {ordersLoading ? (
        <Loader />
      ) : ordersError ? (
        <Message variant="danger">
          {ordersError.message || "An error occurred"}
        </Message>
      ) : (
        <>
          <h2>Summary</h2>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>STATE</th>
                <th>TOTAL ORDERS</th>
                <th>TOTAL ITEM COST</th>
                <th>TOTAL TAX COLLECTED</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{state}</td>
                <td>{summaryData?.totalOrders ?? "N/A"}</td>
                <td>${summaryData?.totalItemCosts?.toFixed(2) ?? "N/A"}</td>
                <td>${summaryData?.totalTaxCollected?.toFixed(2) ?? "N/A"}</td>
              </tr>
            </tbody>
          </Table>

          <h2>Orders</h2>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ORDER ID</th>
                <th>DATE</th>
                <th>ITEM COUNT</th>
                <th>TOTAL ITEM COST</th>
                <th>TAX COLLECTED</th>
                <th>SHIPPING COLLECTED</th>
                <th>HANDLING COLLECTED</th>
                <th>TOTAL ORDER</th>
                <th>DETAILS</th>
              </tr>
            </thead>
            <tbody>
              {ordersData?.length ? (
                ordersData.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>
                      {new Date(order.createdAt)
                        .toLocaleString("en-US", {
                          timeZone: "America/Los_Angeles", // Pacific Time (PT)
                          hour12: false,
                        })
                        .substring(0, 19) + " (PT)"}
                    </td>
                    <td>{order.orderItems.length}</td>
                    <td>${order.itemsPrice.toFixed(2)}</td>
                    <td>${order.taxPrice.toFixed(2)}</td>
                    <td>${order.shippingPrice.toFixed(2)}</td>
                    <td>${order.handlingFee.toFixed(2)}</td>
                    <td>${order.totalPrice.toFixed(2)}</td>
                    <td>
                      <Link to={`/order/${order._id}`}>
                        <Button variant="light" className="btn-sm">
                          Details
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">No orders found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default StateTaxDetailsScreen;
