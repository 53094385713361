import {
  SUBMISSION_UPLOAD_URL,
  RESTORATION_URL,
  PART_URL,
  CAREERS_URL,
} from "../constants";
import { apiSlice } from "./apiSlice";

export const submissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    submitRestoration: builder.mutation({
      query: (formData) => ({
        url: RESTORATION_URL,
        method: "POST",
        body: formData,
      }),
    }),
    submitPart: builder.mutation({
      query: (formData) => ({
        url: PART_URL,
        method: "POST",
        body: formData,
      }),
    }),
    submitCareer: builder.mutation({
      query: (formData) => ({
        url: CAREERS_URL,
        method: "POST",
        body: formData,
      }),
    }),
    uploadRestorationSubmissionFile: builder.mutation({
      query: (formData) => ({
        url: `${SUBMISSION_UPLOAD_URL}/restoration`,
        method: "POST",
        body: formData,
      }),
    }),
    uploadPartSubmissionFile: builder.mutation({
      query: (formData) => ({
        url: `${SUBMISSION_UPLOAD_URL}/part`,
        method: "POST",
        body: formData,
      }),
    }),
    uploadCareerSubmissionFile: builder.mutation({
      query: (formData) => ({
        url: `${SUBMISSION_UPLOAD_URL}/careers`,
        method: "POST",
        body: formData,
      }),
    }),
    getRestoration: builder.query({
      query: ({ pageNumber }) => ({
        url: RESTORATION_URL,
        params: {
          pageNumber,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getRestorationById: builder.query({
      query: (restorationId) => ({
        url: `${RESTORATION_URL}/${restorationId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getPart: builder.query({
      query: ({ pageNumber }) => ({
        url: PART_URL,
        params: {
          pageNumber,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getPartById: builder.query({
      query: (partId) => ({
        url: `${PART_URL}/${partId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getCareer: builder.query({
      query: ({ pageNumber }) => ({
        url: CAREERS_URL,
        params: {
          pageNumber,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getCareerById: builder.query({
      query: (careerId) => ({
        url: `${CAREERS_URL}/${careerId}`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useSubmitRestorationMutation,
  useSubmitPartMutation,
  useSubmitCareerMutation,
  useUploadRestorationSubmissionFileMutation,
  useUploadPartSubmissionFileMutation,
  useUploadCareerSubmissionFileMutation,
  useGetRestorationQuery,
  useGetRestorationByIdQuery,
  useGetPartQuery,
  useGetPartByIdQuery,
  useGetCareerQuery,
  useGetCareerByIdQuery,
} = submissionApiSlice;
