import React from "react";
import { useState } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  ListGroup,
  Form,
  Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Meta from "../components/Meta";
import {
  useSubmitPartMutation,
  useUploadPartSubmissionFileMutation,
} from "../slices/submissionApiSlice";
import Loader from "../components/Loader";

const PartScreen = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [partType, setPartType] = useState("");
  const [details, setDetails] = useState("");
  const [file, setFile] = useState(null);

  const [submitPart, { isLoading: isSubmitting }] = useSubmitPartMutation();
  const [uploadPartSubmissionFile, { isLoading: isUploading }] =
    useUploadPartSubmissionFileMutation();

  const uploadFileHandler = async (e) => {
    const fileData = new FormData();
    fileData.append("file", e.target.files[0]);
    fileData.append("formType", "part");
    try {
      const uploadResponse = await uploadPartSubmissionFile(fileData).unwrap();
      toast.success("File uploaded successfully!");
      setFile(uploadResponse.file);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const formData = {
      name,
      email,
      phone,
      subject,
      partType,
      details,
      file,
    };
    try {
      await submitPart(formData).unwrap();
      toast.success("Submitting form...");
      navigate("/parts");
      toast.success("Form and file submitted successfully!");
      // Clear the form
      setName("");
      setEmail("");
      setPhone("");
      setSubject("");
      setPartType(""); // Clear the partType state
      setDetails(""); // Clear the details state
      setFile(null);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <>
      <Meta
        title="Parts | Lugo Parts & Restoration"
        description="Are you in search of a specific part for your GMC restoration project? From engines and interiors to custom pieces, we provide authentic and high-quality components to meet your needs."
        keywords="Parts, Catalog, Classic GMC Trucks, Engines, Interiors, Custom Pieces, Authentic, High-Quality, Restoration, Vintage, Automotive, Shopping, Online, Secure, Reliable, Wide Range, Exclusive, Customer Service, Quality Assurance"
      />

      <Container>
        {/* Parts Section */}
        <section className="about-me mt-5 mb-5">
          <h3 className="mb-5">Your Treasure Trove for Vintage Truck Parts</h3>
          <Row className="align-items-center">
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Discover a World of Possibilities at Lugo Parts - Your
                    Ultimate Source for 1955-1959 Chevrolet and GMC Truck Parts!
                    Immerse yourself in a treasure trove of new and used
                    components meticulously curated for classic enthusiasts.
                    <br />
                    <br />
                  </p>
                  <p className="landing-page-text">
                    Unearth a wide selection of top-quality parts that breathe
                    life into your restoration dreams. From authentic OEM
                    components to carefully sourced pre-loved treasures, our
                    inventory spans the spectrum to cater to every restoration
                    project. At Lugo Parts, we're not only dedicated to
                    providing the highest caliber parts, but we're also
                    committed to making them accessible by offering competitive
                    pricing.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={6}>
              <Image src="/images/compressed/webp/Parts01.webp" fluid rounded />
            </Col>
          </Row>
        </section>

        {/* Parts Brand Section */}
        <section className="certified-parts mt-5 mb-5">
          <h3 className="mb-5">Certified Parts and Trusted Brands</h3>
          <Row className="align-items-center">
            <Col md={9}>
              <p className="landing-page-text">
                At Lugo Parts & Restoration, we take pride in offering parts
                that are not just high-quality but also certified by trusted
                brands. Our range of parts comes with the assurance of quality,
                backed by the reputation of brands like NAPCO, Chevrolet, and
                GMC.
                <br />
                <br />
                Explore our collection of certified parts and take your
                restoration project to the next level with components you can
                trust. We understand that the success of your restoration
                journey hinges on the quality and authenticity of the parts you
                use. That's why we go the extra mile to source parts that meet
                stringent quality standards.
                <br />
                <br />
                With Lugo Parts & Restoration, you're not just buying parts;
                you're investing in quality, reliability, and the ultimate
                success of your restoration project.
              </p>
            </Col>
          </Row>
          {/* For Sticker Desktop View */}
          <Row className="align-items-center mt-4 justify-content-between d-none d-md-flex">
            <Col xs={6} md={4}>
              <Image
                src="/images/compressed/stickers/webp/GMCinMotor.webp"
                fluid
              />
            </Col>
            <Col xs={6} md={4}>
              <Image
                src="/images/compressed/stickers/webp/ChevroletBigWheel.webp"
                fluid
              />
            </Col>
            <Col xs={6} md={4}>
              <Image src="/images/compressed/stickers/GMCcoach.png" fluid />
            </Col>
          </Row>
          <Row className="align-items-center mt-4 justify-content-between d-none d-md-flex">
            <Col xs={6} md={4}>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/compressed/stickers/webp/NapcoNewSize.webp"
                  fluid
                />
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/compressed/stickers/webp/FleetsideNapco.webp"
                  fluid
                />
              </div>
            </Col>
            <Col xs={6} md={4}>
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/compressed/stickers/webp/BlueChip1.webp"
                  fluid
                />
              </div>
            </Col>
          </Row>

          {/* For Sticker Mobile View */}
          <Row className="align-items-center mt-4 justify-content-between d-md-none d-flex flex-column">
            <Col className="mb-2">
              <Image
                src="/images/compressed/stickers/webp/GMCinMotor.webp"
                fluid
              />
            </Col>

            <Col className="mb-2">
              <Image
                src="/images/compressed/stickers/webp/GMCcoach.webp"
                fluid
              />
            </Col>
            <Col className="mb-2">
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/compressed/stickers/webp/FleetsideNapco.webp"
                  fluid
                />
              </div>
            </Col>
            <Col className="mb-2">
              <Image
                src="/images/compressed/stickers/webp/ChevroletBigWheel.webp"
                fluid
              />
            </Col>
            <Col className="mb-2">
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/compressed/stickers/webp/NapcoNewSize.webp"
                  fluid
                />
              </div>
            </Col>
            <Col className="mb-2">
              <div className="d-flex justify-content-center">
                <Image
                  src="/images/compressed/stickers/webp/BlueChip1.webp"
                  fluid
                />
              </div>
            </Col>
          </Row>
        </section>

        {/* Parts Inquiry Section */}
        <section className="custom-inquiry mt-5 mb-5">
          <h3 className="mb-5" style={{ textAlign: "center" }}>
            Inquire About Rare and Restorable Truck Parts
          </h3>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Step into a world where classic truck restoration
                    aspirations become reality.
                    <br />
                    <br />
                    Fill out the form below with your specific needs, and our
                    dedicated team will guide you through your selection,
                    ensuring a seamless experience from start to finish.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="my-3">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="email" className="my-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="phone" className="my-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter your phone number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="subject" className="my-3">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="partType" className="my-3">
              <Form.Label>Type of Part</Form.Label>
              <Form.Select
                value={partType}
                onChange={(e) => setPartType(e.target.value)}
                required
              >
                <option value="">Select Type of Part</option>
                <option value="Rare">Rare</option>
                <option value="Restorable Used Item">
                  Restorable Used Item
                </option>
                <option value="OEM">OEM</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="details" className="my-3">
              <Form.Label>Part Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Describe the specific part(s) you are inquiring about..."
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="file" className="my-3">
              <Form.Label>File Upload (PDF)</Form.Label>
              <Form.Control type="file" onChange={uploadFileHandler} />
            </Form.Group>

            <Button
              type="submit"
              variant="primary"
              className="mt-2"
              disabled={isSubmitting || isUploading}
            >
              Submit Inquiry
            </Button>
            {(isSubmitting || isUploading) && <Loader />}
          </Form>
        </section>
      </Container>
    </>
  );
};

export default PartScreen;
