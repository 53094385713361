import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import { useSendResetEmailMutation } from "../slices/usersApiSlice";
import { toast } from "react-toastify";

const ForgotPasswordScreen = () => {
  const [email, setEmail] = useState("");
  const [sendResetEmail, { isLoading }] = useSendResetEmailMutation();
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await sendResetEmail(email).unwrap();
      toast.success("Password reset email sent. Please check your inbox.");
      navigate("/login");
    } catch (err) {
      if (err?.originalStatus === 404) {
        toast.error("Email address does not exist.");
      } else {
        toast.error(
          err?.data?.message || "Unable to send password reset email."
        );
      }
    }
  };

  return (
    <>
      <Meta title="Forgot Password | Lugo Parts & Restoration" />

      <FormContainer>
        <h1>Forgot Password</h1>
        <p>Enter your email address to receive a password reset link.</p>

        <Form onSubmit={submitHandler}>
          <Form.Group controlId="email" className="my-3">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary" disabled={isLoading}>
            Send Password Reset Email
          </Button>

          {isLoading && <Loader />}
        </Form>

        <Row className="py-3">
          <Col>
            Remembered your password? <Link to="/login">Sign in</Link>
          </Col>
        </Row>
      </FormContainer>
    </>
  );
};

export default ForgotPasswordScreen;
