// StateTaxRatesFront.js

const StateTaxRatesFront = {
  Alabama: 0.04,
  Arizona: 0.056,
  Arkansas: 0.065,
  California: 0.0725,
  Colorado: 0.029,
  Connecticut: 0.0635,
  Delaware: 0.0,
  "District of Columbia": 0.06,
  Florida: 0.06,
  Georgia: 0.04,
  Idaho: 0.06,
  Illinois: 0.0625,
  Indiana: 0.07,
  Iowa: 0.06,
  Kansas: 0.065,
  Kentucky: 0.06,
  Louisiana: 0.045,
  Maine: 0.055,
  Maryland: 0.06,
  Massachusetts: 0.0625,
  Michigan: 0.06,
  Minnesota: 0.0688,
  Mississippi: 0.07,
  Missouri: 0.0423,
  Montana: 0.0,
  Nebraska: 0.055,
  Nevada: 0.0685,
  "New Hampshire": 0.0,
  "New Jersey": 0.0663,
  "New Mexico": 0.0488,
  "New York": 0.04,
  "North Carolina": 0.0475,
  "North Dakota": 0.05,
  Ohio: 0.0575,
  Oklahoma: 0.045,
  Oregon: 0.0,
  Pennsylvania: 0.06,
  "Rhode Island": 0.07,
  "South Carolina": 0.06,
  "South Dakota": 0.042,
  Tennessee: 0.07,
  Texas: 0.0625,
  Utah: 0.061,
  Vermont: 0.06,
  Virginia: 0.053,
  Washington: 0.065,
  "West Virginia": 0.06,
  Wisconsin: 0.05,
  Wyoming: 0.04,
};

export default StateTaxRatesFront;
