import React from "react";
import { Row, Col, Container, ListGroup } from "react-bootstrap";
import Meta from "../components/Meta";

const ReturnPolicy = () => {
  return (
    <>
      <Meta
        title="Return Policy | Lugo Parts & Restoration"
        description="Learn about our straightforward and customer-friendly return policy for classic GMC truck parts and services. We aim to make your shopping experience as smooth as possible."
        keywords="Return Policy, Customer-Friendly, Shopping Experience, Smooth, Classic GMC Trucks, Restoration, Projects, Vintage, Automotive, Quality, Professional, Reliable, Customer Service, Policy, Returns, Refunds, Exchanges, Terms, Conditions"
      />

      <Container>
        {/* Return Policy Header */}
        <section className="return-policy-header mt-5 mb-5">
          <h3 className="mb-5">Return Policy</h3>
        </section>

        {/* 14-Day Return Policy */}
        <section className="fourteen-day-policy mt-5 mb-5">
          <h4>14-Day Return Policy</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Lugo Parts & Restoration offers a{" "}
                    <strong>14-day return policy</strong> for eligible products,
                    which include automotive parts, accessories, and apparel.
                    Returns are accepted within 14 days of the customer
                    receiving the product. To qualify for a return, the product
                    must meet the following criteria:
                    <br />
                    <br />
                    <ul>
                      <li>
                        The product must be in its original condition, unused,
                        and in the same packaging in which it was received.
                      </li>
                      <li>
                        If you received an incorrect item (e.g., wrong part or
                        accessory), it is eligible for a return and full refund.
                      </li>
                      <li>
                        Customized or personalized items are not eligible for
                        return.
                      </li>
                      <li>
                        Products that have been installed or tampered with
                        cannot be returned.
                      </li>
                    </ul>
                    <br />
                    Please note that all returns are subject to verification and
                    approval by our team.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Damaged Orders */}
        <section className="damaged-orders mt-5 mb-5">
          <h4>Damaged Orders</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    If an order is received damaged, the customer is{" "}
                    <strong>
                      required to send a photo of the box before opening it
                    </strong>
                    . Failure to comply may result in the return being
                    ineligible.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>

        {/* Restocking Fee */}
        <section className="restocking-fee mt-5 mb-5">
          <h4>Restocking Fee</h4>
          <Row className="align-items-center">
            <Col md={12}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <p className="landing-page-text">
                    Please note that a <strong>20% restocking fee</strong> will
                    be applied to all returns. This fee covers the cost of
                    processing the return and restocking the product.
                  </p>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default ReturnPolicy;
