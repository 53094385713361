import React from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Paginate = ({ pages, page, isAdmin = false, keyword = "" }) => {
  const pageLimit = 5; // Number of pagination buttons to show at once

  // Calculate the range of visible pagination buttons
  const startPage = Math.max(1, page - Math.floor(pageLimit / 2));
  const endPage = Math.min(pages, startPage + pageLimit - 1);

  const generateLink = (pageNum) => {
    return !isAdmin
      ? keyword
        ? `/store/search/${keyword}/page/${pageNum}`
        : `/store/page/${pageNum}`
      : keyword
      ? `/admin/productlist/search/${keyword}/page/${pageNum}`
      : `/admin/productlist/page/${pageNum}`;
  };

  return (
    pages > 1 && (
      <Pagination>
        {/* First Button */}
        {page !== 1 && (
          <LinkContainer to={generateLink(1)}>
            <Pagination.First />
          </LinkContainer>
        )}

        {/* Previous Button */}
        {page !== 1 && (
          <LinkContainer to={generateLink(Math.max(1, page - 1))}>
            <Pagination.Prev />
          </LinkContainer>
        )}

        {/* Page Number Buttons */}
        {Array.from(
          { length: endPage - startPage + 1 },
          (_, i) => startPage + i
        ).map((pageNum) => (
          <LinkContainer key={pageNum} to={generateLink(pageNum)}>
            <Pagination.Item active={pageNum === page}>
              {pageNum}
            </Pagination.Item>
          </LinkContainer>
        ))}

        {/* Next Button */}
        {page !== pages && (
          <LinkContainer to={generateLink(Math.min(pages, page + 1))}>
            <Pagination.Next />
          </LinkContainer>
        )}

        {/* Last Button */}
        {page !== pages && (
          <LinkContainer to={generateLink(pages)}>
            <Pagination.Last />
          </LinkContainer>
        )}
      </Pagination>
    )
  );
};

export default Paginate;
