import { apiSlice } from "./apiSlice";
import { GUESTS_URL } from "../constants"; // Make sure you define this constant

export const guestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createGuestUser: builder.mutation({
      query: (email) => ({
        url: GUESTS_URL, // Updated to use the base URL
        method: "POST",
        body: { email },
      }),
    }),
    // Add other guest-related endpoints here if needed in future
  }),
});

// Export hooks for usage in components
export const { useCreateGuestUserMutation } = guestsApiSlice;
